// import { initiate } from "./crossStorage";

// initiate();

// class SessionHelper {
//   setToken(Token) {
//     localStorage.setItem("Token", Token);
//   }
//   getToken() {

//     return localStorage.getItem("Token");

//   }
//   setUserDetails(UserDetails) {
//     //localStorage.setItem("UserDetails", JSON.stringify(UserDetails));
//     //fetchDataDomain();
//   }
//   getUserDetails() {


//     //var user = localStorage.getItem("UserDetails");
//     var user = "";

//     console.log("hi session 1");
//     if (user !== null) {
//       console.log("hi session 2");
//       return JSON.parse(localStorage.getItem("UserDetails"));
//        // return user;
//     } else {
//       console.log("hi session 3");
//       return user;
//     }
//   }

//   setEmail(Email) {
//     localStorage.setItem("Email", Email);
//   }
//   getEmail() {
//     return localStorage.getItem("Email");
//   }
//   setOTP(OTP) {
//     localStorage.setItem("OTP", OTP);
//   }
//   getOTP() {
//     return localStorage.getItem("OTP");
//   }
//   removeSessions = () => {
//     localStorage.clear();
//     window.location.href = "/login";
//   };
// }
// export const {
//   setEmail,
//   getEmail,
//   setOTP,
//   getOTP,
//   setToken,
//   getToken,
//   setUserDetails,
//   getUserDetails,
//   removeSessions,
// } = new SessionHelper();
class SessionHelper {
  setToken(Token) {
    localStorage.setItem("Token", Token);
  }
  getToken() {
    return localStorage.getItem("Token");
  }
  setUserDetails(UserDetails) {
    localStorage.setItem("UserDetails", JSON.stringify(UserDetails));
  }
  getUserDetails() {
    const user = localStorage.getItem("UserDetails");
    console.log("hi session 1");
    if (user !== "undefined") {
      console.log("hi session 2");
      return JSON.parse(localStorage.getItem("UserDetails"));
    } else {
      console.log("hi session 3");
      return user;
    }
  }
  setEmail(Email) {
    localStorage.setItem("Email", Email);
  }
  getEmail() {
    return localStorage.getItem("Email");
  }
  setMobile(Email) {
    localStorage.setItem("Mobile", Email);
  }
  getMobile() {
    return localStorage.getItem("Mobile");
  }
  setOTP(OTP) {
    localStorage.setItem("OTP", OTP);
  }
  getOTP() {
    return localStorage.getItem("OTP");
  }
  removeSessions = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
}
export const {
  setEmail,
  getEmail,
  setOTP,
  getOTP,
  setToken,
  setMobile,
  getMobile,

  getToken,
  setUserDetails,
  getUserDetails,
  removeSessions,
} = new SessionHelper();
