import { configureStore } from "@reduxjs/toolkit";
// import { logoutUser, setToken, setUser } from "./feature/Authentication/AuthenticationSlice";
import { api } from "./API/ApiSlice";
import AuthenticationReducer from "./feature/Authentication/AuthenticationSlice";
import UsersReducer from "./feature/Users/UsersSlice";
import CategoryReducer from "./feature/Category/CategorySlice";
import SellerReducer from "./feature/Seller/SellerSlice";
import ImageLoaderReducer from "./feature/ImageLoader/ImageLoaderSlice";
import { composeWithDevTools } from 'redux-devtools-extension';


const store = configureStore({
    reducer:{
        Authentication:AuthenticationReducer,
        Users:UsersReducer,
        Category:CategoryReducer,
        Seller:SellerReducer,
        ImageLoader:ImageLoaderReducer,
        [api.reducerPath]:api.reducer

    },

    middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(api.middleware),
    // devTools:[composeWithDevTools()]
    // enhancers: [composeWithDevTools()]
})

export default store