import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer">
			<div className="copyright border-top">
				<p>Copyright © {d.getFullYear()} OWNFOOD | Tel: 09643123456 | Info@ownfood.com |  Developed by{" "}
					<a href="https://www.smartframeworkbd.com" target="_blank"  rel="noreferrer">
						Smart Framework
					</a>{" "}
				</p>
			</div>
		</div>
	);
};

export default Footer;
