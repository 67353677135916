import { createSlice } from "@reduxjs/toolkit";

const SellerSlice = createSlice({
    name:'seller',
    initialState:{
        getFoodBySellerList:[]
    },
    reducers:{
        GetFoodBySeller:(state,action)=>{
            state.getFoodBySellerList=action.payload
        }
    }
})
export const {GetFoodBySeller} = SellerSlice.actions
export default SellerSlice.reducer