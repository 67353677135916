import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Row, Card, Col, Button } from "react-bootstrap";
import { getUserDetails } from "../Helper/SessionHelper";
import {
  useGetRiderInfoQuery,
  useGetSellerInfoQuery,
} from "../Redux/API/ApiSlice";
import RunBoy from "../images/avatar/giphy.gif";
const BecomeRiderProtectedRouter = ({ children }) => {
  const userData = getUserDetails();
  const { data: riderData, isSuccess: sellerSuccess } = useGetRiderInfoQuery(
    userData._id
  );
  // console.log(sellerData?.data[0]?.seller[0]?._id);
  // Use state to track whether the 30-second timeout has elapsed
  const [timeoutElapsed, setTimeoutElapsed] = useState(false);
  useEffect(() => {
    if (
      riderData?.data[0] &&
      riderData?.data[0]?.riderInfo[0]?.riderApproval === "Pending"
    ) {
      // Start a 30-second timeout
      const timeoutId = setTimeout(() => {
        // After 30 seconds, set the timeoutElapsed state to true
        setTimeoutElapsed(true);
      }, 5000); // 30 seconds in milliseconds

      // Return a cleanup function to clear the timeout if the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [riderData]);

  console.log(riderData, "from seller data from protected route ");
  if (
    riderData?.data[0] &&
    riderData?.data[0]?.riderInfo[0]?.riderApproval === "Pending"
  ) {
    return (
      <Row>
        <Col xl={6}>
          <Card className="text-white bg-secondary">
            <Card.Header>
              <Card.Title className="text-white">
                Your Rider Application is in review.
              </Card.Title>
            </Card.Header>
            <Card.Body className="mb-0">
              <Card.Text>
                If your want to update your rider information, switch to rider
                account from Profile.
              </Card.Text>
              {/* <Button
                as='a'
                variant='link'
                href='/edit-profile'
                className='btn-card bg-white text-secondary mt-3'
              >
                Click here to switch to seller account.
              </Button> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  } else if (
    riderData?.data[0] &&
    riderData?.data[0]?.riderInfo[0]?.riderApproval === "Approved"
  ) {
    return (
      <Row>
        <Col xl={6}>
          <Card className="text-white bg-secondary">
            <Card.Header>
              <Card.Title className="text-white">
                Your rider Application is under review.
              </Card.Title>
            </Card.Header>
            <Card.Body className="mb-0">
              <Card.Text>
                Our rider mentor team will review it and will approve it
                accordingly. In the meantime, if you want to update your
                information, go to Settings -&gt; Update Profile.
              </Card.Text>
              <Button
                as="a"
                variant="link"
                href="/edit-profile"
                className="btn-card bg-white text-secondary mt-3"
              >
                Update Rider Information
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  } else if (
    riderData?.data[0] &&
    riderData?.data[0]?.riderInfo[0]?.riderApproval === "Draft"
  ) {
    // If the sellerApproval is "Draft" and the 30-second timeout has elapsed, redirect
    if (timeoutElapsed) {
      return (
        <Navigate
        //   to={`/become-seller-next-step?id=${sellerData?.data[0]?.seller[0]?._id}`}
        />
      );
    } else {
      return (
        <Row>
          <Col xl={6}>
            <Card className="text-white bg-secondary">
              <Card.Header>
                <Card.Title className="text-white">
                  Your Seller Application is incomplete.
                </Card.Title>
              </Card.Header>
              <Card.Body className="mb-0">
                <div className="row">
                  <div className="col-8">
                    <Card.Text>
                      Your seller application is incomplete. Now you are
                      redirected to incomplete step to finish your seller
                      application.
                    </Card.Text>
                    <Button
                      as="a"
                      variant="link"
                      //   href={`/become-seller-next-step?id=${sellerData?.data[0]?.seller[0]?._id}`}
                      className="btn-card bg-white text-secondary mt-3"
                    >
                      Click here to Continue to Next Step
                    </Button>
                  </div>
                  <div className="col-4">
                    <div>
                      <img alt="" className="img-fluid" src={RunBoy} />
                      {!timeoutElapsed ? " Waiting..." : ""}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      );
    }
  } else return children;
};
export default BecomeRiderProtectedRouter;
