import React from 'react';
import CouponCard from './Coupon';
import { useGetBuyerCouponQuery } from '../../../Redux/API/ApiSlice';
import { getUserDetails } from '../../../Helper/SessionHelper';

const CouponList = () => {
    const user = JSON.parse(localStorage.getItem("UserDetails"));

    console.log(user._id);

    
    
    const {data,isSuccess,isLoading}=useGetBuyerCouponQuery( getUserDetails()?._id)
    
      
  return (
    <div className="container row   mt-5">
        {
            isSuccess && data.data.map(item=><CouponCard data={item} />)
        }
     
    </div>
  );
};

export default CouponList;
