import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";

import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import MainNavSide from "./nav/MainNavSide";
import { useSelector } from "react-redux";
import { getSellerAPI } from "../../API/SellerAPI";
import { getUserDetails } from "../../Helper/SessionHelper";
import { NavMenuToggle } from "./nav/NavHader";
import LocationTracker from "../Rider/LocationTracker";

const Main = () => {
  const [toggle, setToggle] = useState(false);
  const wrapperRef = useRef(null);
  const { menuToggle, openMenuToggle } = useContext(ThemeContext);

  // const userData = getUserDetails();
  // const [sidebarOpen, setSidebarOpen] = useState(menuToggle);
  // useEffect(() => {
  //   // Call handleOutsideClick when the component mounts
  //   const handleOutsideClick = (event) => {

  //     if ( !menuToggle && wrapperRef.current && wrapperRef.current.contains(event.target)) {
  //       console.log("Clicked outside of the sidebar",
  //       menuToggle);
  //       NavMenuToggle();

  //       setToggle(!toggle);
  //       // openMenuToggle();

  //     }
  //   };

  //   // Add the click event listener when the component mounts
  //   document.addEventListener("mousedown", handleOutsideClick);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [toggle]);

  // useEffect(() => {
  //   // Add event listener to detect clicks outside the sidebar
  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [handleOutsideClick]);

  // console.log(seller, "user");
  let riderId;
  if (getUserDetails()?.riderInf) {
    riderId = getUserDetails()?.riderInfo[0]?._id;
  }
  return (
    <div
      id='main-wrapper'
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <LocationTracker riderId={riderId} />
      <MainNavSide setToggle={setToggle} toggle={toggle} />
      <div
        ref={wrapperRef}
        className='content-body'
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className='container'>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
