import React, { useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Accordion, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";

import axios from "axios";

import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { useEffect } from "react";
import getTranslation from "../../../Helper/getTranslationUtility";
import { LanguageContext } from "../../../context/LanguageContext";
import labels from "../../../translationData/howDo.json"

const CreateSubCategory = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm();

  const [data, setData] = useState({
    categoryName: "",
    categorySlug: "",
  });
  // const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const sellerID = getUserDetails().sellerIDInfo[0]?._id;
  const [catID, setCatID] = useState([]);
  const [titleErMsg, setTitleErMsg] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const [categoryTitle, setCategoryTitle] = useState("");

  // Get the token from local storage
const token = localStorage.getItem("Token");
const headers = token ? { token: `${token}` } : {}; // Set token header


  const getCat = async () => {
    await axios
      .get(`${BaseURL}/get-category`, { headers })
      .then((res) => setCatID(res.data.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCat();
  }, []);
  const [cat, setcat] = useState();
  const Fcat = (newcat) => {
    setcat(newcat);
  };

  const [selectedImage, setSelectedImage] = useState([]);
  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);

    setSelectedImage(fileList);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    // console.log("Selected fruit:", selectedValue);
  };


// Validation check for sub-category title existence
const handleTitleExist2 = async (title) => {
  setCategoryTitle(title);
  try {
    let result = await axios.get(
      `${BaseURL}/sub-category-validation/${sellerID}/${title}`,
      { headers }
    );
    if (result.data.status === 200) {
      setTitleErMsg(result.data.message);
      setIsTitleValid(true);
    } else {
      setTitleErMsg("");
      setIsTitleValid(false);
    }
  } catch (error) {
    setTitleErMsg("Error validating title");
    setIsTitleValid(true);
  }
};


  const handleTitleExist = useCallback(async (event) => {
    let titleExist = event.target.value;
    setCategoryTitle(titleExist);
    try {
      let result = await axios.get(
        `${BaseURL}/sub-category-validation/${sellerID}/${titleExist}`,
        { headers }
      );
      if (result.data.status === 200) {
        setTitleErMsg(result.data.message);
        setIsTitleValid(true);
      } else {
        setTitleErMsg("");
        setIsTitleValid(false);
      }
    } catch (error) {
      console.error("Error fetching validation status:", error);
      setTitleErMsg("Error validating title");
      setIsTitleValid(true);
    }
  }, []);
  

// Handle sub-category creation with token in headers
const handleSubcategory = async (value) => {
  const name = categoryTitle;
  handleTitleExist2(name);
  const selectedCategory = value.selectedCategory;

  if (isTitleValid) {
    toast.success("Title already exists!", {
      position: "bottom-center",
      autoClose: 5000,
    });
    return;
  } else {
    const subdata = {
      categoryName: name,
      categoryID: selectedCategory,
      status: true,
      sellerID: sellerID,
      createdBy: sellerID,
    };

    await axios
      .post(`${BaseURL}/create-subcategory`, subdata, { headers })
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success("SubCategory/Menu Label has been created successfully.", {
            position: "bottom-center",
            autoClose: 5000,
          });
        } else {
          toast.error("Unable to create your subcategory", {
            position: "bottom-center",
            autoClose: 5000,
          });
        }

        // Error handling for unique slug
        if (res.data.data.keyPattern?.categorySlug === 1) {
          toast.error("Slug should be unique", {
            position: "bottom-center",
            autoClose: 5000,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.error("Error creating subcategory:", err);
      });
  }
};

         // Function to change the language
 const changeLanguage = (language) => {
  setCurrentLanguage(language);
};

  //Tab labels:
  const MenuLabel = getTranslation(
    "MenuLabel",
    currentLanguage,
    labels
  );
  const CreateSubCategory = getTranslation(
    "CreateSubCategory",
    currentLanguage,
    labels
  );
  const SelectParentCategory = getTranslation(
    "SelectParentCategory",
    currentLanguage,
    labels
  );
  const SelectCategory = getTranslation(
    "SelectCategory",
    currentLanguage,
    labels
  );
  const SubCategoryTitle = getTranslation(
    "SubCategoryTitle",
    currentLanguage,
    labels
  );
  const labelSave = getTranslation(
    "labelSave",
    currentLanguage,
    labels
  );

  return (
    <>
      <ToastContainer />{" "}
      <div className="container d-flex ">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{CreateSubCategory}/{MenuLabel}</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSubmit(handleSubcategory)}>
                  <div className="row d-flex">
                    <div className="form-group mb-3 col-md-4 col-lg-12">
                      <label>{SelectParentCategory} *</label>
                      <select
                        // value={selectedCategory}
                        onChange={handleOptionChange}
                        // id="inputState"
                        className="form-control"
                        id="selectedCategory"
                        {...register("selectedCategory", {
                          required: true,
                        })}
                      >
                        <option value="">{SelectCategory}</option>
                        {catID.map((item, index) => (
                          <option key={index} value={item._id}>
                            {console.log(item)}
                            {item.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.selectedCategory && (
                        <span className="text-danger">{SelectCategory}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label>{SubCategoryTitle} *</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={SubCategoryTitle}
                      onChange={(e) => handleTitleExist(e)}
                      name="categoryName"
                      // {...register("categoryName", { required: true })}
                    />

                    {titleErMsg && (
                      <span className="text-danger">{titleErMsg}</span>
                    )}
                  </div>

                  <Button
                    style={{ marginLeft: 25 }}
                    type="submit"
                    className="mt-3"
                  >
                    {labelSave}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSubCategory;
