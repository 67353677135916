import { Link, useParams } from "react-router-dom";
import "../../../css/trackorder.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import FormModal from "../Modal/FormModal";
import Driver from "../../components/Driver";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { useGetSingleOrderQuery } from "../../../Redux/API/ApiSlice";
import { onValue, ref } from "firebase/database";
import { database } from "../../../Helper/firebase";
import { toast } from "react-toastify";

function TrackOrder() {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data,
    isLoading: loade,
    error: erro,
  } = useGetSingleOrderQuery(id);


  const [show, setShow] = useState(false);

  const [riderLocation, setRiderLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const [restaurantLocation, setRestaurantLocation] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (show && data && data.RiderId) {
      const riderRef = ref(database, `drivers/${data.RiderId}`);

      const unsubscribe = onValue(
        riderRef,
        (snapshot) => {
          const locationData = snapshot.val();
          if (locationData) {
            setRiderLocation({
              lat: locationData.lat,
              lng: locationData.lng,
            });
          } else {
            console.error("No location data found.");
            toast.error("No location data found for this rider.");
          }
        },
        (error) => {
          console.error("Error fetching location data:", error);
          toast.error("Error fetching location data.");
        }
      );

      return () => unsubscribe();
    }
  }, [show, data]);

  const handleTrack = (data) => {
    setRestaurantLocation({
      lat: data?.data?.[0]?.seller?.pointLocation.coordinates[1],
      lng: data?.data?.[0]?.seller?.pointLocation.coordinates[0],
    });
    // console.log("try brose", data?.data?.[0]?.seller?.pointLocation.coordinates[0]);
    setShow(true);;
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setIsLoading(true);
        
        // Retrieve token from localStorage
        const token = localStorage.getItem("Token");
        const headers = token ? { token: `${token}` } : {};
  
        const response = await axios.get(
          `https://api.ownfood.com.bd/api/v1/get-order-tracking-by-order/${id}`,
          { headers }  // Pass headers here
        );
  
        setOrderDetails(response.data?.data[0]);
        setIsLoading(false);
      } catch (error) {
        setError("Failed to fetch order details");
        setIsLoading(false);
      }
    };
  
    fetchOrderDetails();
  }, [id]);
  

  // Handle loading state
  if (isLoading) {
    return <p>Loading...</p>;
  }

  // Handle error state
  if (error) {
    return <p>{error}</p>;
  }

  // Render order details and tracking info
  return (
    <>
      <FormModal handleClose={handleClose} show={show}>
        <div>
          <Driver
            restaurantLocation={restaurantLocation}
            riderLocation={riderLocation}
            showRestaurantMarker={true}
            showRiderMarker={true}
          />
        </div>
      </FormModal>

      <div>
        <button
          className="btn btn-secondary"
          onClick={() => {
            handleTrack(data);
          }}
        >
         Live Track
        </button>
      </div>

      <div className="row">
        <div className="col-xl-4 col-xxl-6 col-lg-6">
          <div className="card h-auto">
            <div className="card-header border-0 pb-0">
              <h4>Track Your Order</h4>
              <h6>Order ID: {id}</h6>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                id="DZ_W_TimeLine"
                className="widget-timeline dlab-scroll height370 ps ps--active-y h-auto"
              >
                <ul className="timeline">
                  {orderDetails?.OrderTrackingInfo?.map(
                    (trackingInfo, index) => (
                      <li key={trackingInfo._id}>
                        <div
                          className={`timeline-badge ${getStatusBadgeClass(
                            trackingInfo.comment
                          )}`}
                        ></div>
                        <Link className="timeline-panel text-muted">
                          <h5 className="mb-0">
                            <strong>{trackingInfo.comment}</strong>
                          </h5>
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Helper function to get the class for timeline badge based on status
function getStatusBadgeClass(comment) {
  if (comment.includes("Arrived")) return "danger";
  if (comment.includes("Pickup")) return "success";
  if (comment.includes("Delivery")) return "warning";
  return "info"; // Default badge class
}

export default TrackOrder;

// <h2>Order Summary</h2>
//       {orderDetails ? (
//         <div>
//           <p><strong>Order Number:</strong> {orderDetails?.orderNumber}</p>
//           <p><strong>Payment Date:</strong> {orderDetails?.paymentDate}</p>
//           <p><strong>Total Amount:</strong> {orderDetails?.orderTotalAmount}</p>
//           <p><strong>Restaurant:</strong> {orderDetails?.orderItems[0]?.sellerInfo[0]?.kitchenName}</p>
//           <p><strong>Delivery Address:</strong> {orderDetails?.orderDeliveryAddress?.addressText}</p>
//           {/* Add more details as needed */}
//         </div>
//       ) : (
//         <p>No order details available</p>
//       )}
