import axios from "axios";

const GetLocationByLatLng = async (lat, lng) => {
  try{
    const apiKey = "AIzaSyC8OF1nF6le5FMM6MPRGNX5qf9EN1-jpz8";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    let country, area, city, state;
    await axios.get(apiUrl).then((response) => {
      if (response.data.results.length > 0) {
        //setAddress(response.data.results[0].formatted_address);
        const address = response.data.results[0].formatted_address;
        //const address = response?.results[0]?.formatted_address;

        const { address_components } = response.data.results[0];
        console.log(address_components, "from debug");
        area =
          address_components.find((component) =>
            component.types.includes("sublocality")
          )?.long_name || "";
        city =
          address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "";
        state =
          address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "";
        country =
          address_components.find((component) =>
            component.types.includes("country")
          )?.long_name || "";

        console.log(
          { country: country, state: state, city: city, area: area },
          "from debug1"
        );
        // return {country:country,state:state,city:city,area:area}
        //   setUserAddress(`${address}`);
        // setChangeUserAddress(add);
        //   localStorage.setItem("address", `${address}`);

        //   const newData = {
        //     latitude: `${latitude}`,
        //     longitude: `${longitude}`,
        //     address: `${address}`,
        //     area: `${area}`,
        //     city: `${city}`,
        //     state: `${state}`,
        //     country: `${country}`,
        //   };
        //   setLocation(newData);
      }
    });
    //   .catch((error) => {
    //     console.error("Error fetching address:", error);
    //   });

    return { country: country, state: state, city: city, area: area };

  }catch(e){

  }

};
export default GetLocationByLatLng;
