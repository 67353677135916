import { api } from "./ApiSlice";

export const FoodApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWishFoodBySeller: build.query({
      query: (id) => `/get-wish-food-by-seller/${id}`,
    }),
    getWishFoodByBuyer: build.query({
      query: (id) => `/get-wish-food-by-user/${id}`,
    }),
    updateSingleFood: build.mutation({
      query: ({ id, data }) => ({
        url: `/update-food/${id}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const { useGetWishFoodBySellerQuery, useGetWishFoodByBuyerQuery,useUpdateSingleFoodMutation } =
  FoodApi;
