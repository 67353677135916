import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useGetBankListQuery } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const bankTab = labels.userDashboard.editProfile.bankTab;
const Bank = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    bankTab
  );
  const errorMessage = getTranslation("errorMessage", currentLanguage, bankTab);

  const { data: banks, isSuccess } = useGetBankListQuery("Bank");
  const [formDisabed, setFormDisabled] = useState(false);
  const [mainData, setMainData] = useState(null);
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const { handleSubmit, register, getValues, reset } = useForm();



  // Handle the 'Become Seller' action
  const handleBecomeSeller = async (value) => {
    const token = localStorage.getItem("Token"); // Retrieve token from localStorage
    const headers = token ? { token: `${token}` } : {}; // Set headers with token
  
    let fdata = {
      user: {},
      seller: { ...getValues() }, // Spread values from the form or state
    };
  
    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata, { headers }) // Pass headers with token
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  // Handle input change for form
  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setMainData((prev) => {
      const data = {
        ...prev,
        [name]: value,
      };
  
      return data;
    });
  };
  
  // Fetch user details on component mount
  useEffect(() => {
    const token = localStorage.getItem("Token"); // Retrieve token from localStorage
    const headers = token ? { token: `${token}` } : {}; // Set headers with token
  
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`, { headers }) // Pass headers with token
      .then((res) => {
        const data = res.data.data[0].sellerData;
        setMainData(data);
  
        reset({
          ...data,
        });
  
        if (data?.bankDetails) {
          setFormDisabled(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]); // Empty dependency array to run once on component mount
  

  return (
    <Tab.Pane eventKey="10">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h3>
                {getTranslation("tabDetailsTitle", currentLanguage, bankTab)}
              </h3>
            </div>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-4 mb-3">
                  <h6 className="mb-0">
                    {getTranslation("accountName", currentLanguage, bankTab)}
                  </h6>
                </div>
                <div className="col-xl-8 mb-3">
                  <input
                    type="text"
                    className="form-control input-default "
                    placeholder={getTranslation(
                      "accountName",
                      currentLanguage,
                      bankTab
                    )}
                    name="bankAccName"
                    {...register("bankDetails[0].bankAccName", {
                      required: false,
                      onChange: handleInputChange,
                    })}
                  />
                </div>
                <div className="col-xl-4 mb-3">
                  <h6 className="mb-0">
                    {getTranslation("accountNo", currentLanguage, bankTab)}
                  </h6>
                </div>
                <div className="col-xl-8 mb-3">
                  <input
                    type="text"
                    className="form-control input-default "
                    placeholder={getTranslation(
                      "accountNo",
                      currentLanguage,
                      bankTab
                    )}
                    name="bankAccNo"
                    {...register("bankDetails[0].bankAccNo", {
                      required: false,
                      onChange: handleInputChange,
                    })}
                  />
                </div>
                <div className="col-xl-4 mb-3">
                  <h6 className="mb-0">
                    {getTranslation("bankName", currentLanguage, bankTab)}
                  </h6>
                </div>
                <div className="col-xl-8 mb-3">
                  <select
                    className="form-control"
                    {...register("bankDetails[0].bankName", {
                      required: false,
                      onChange: handleInputChange,
                    })}
                  >
                    <option value="">
                      {getTranslation(
                        "selectBankPlaceholder",
                        currentLanguage,
                        bankTab
                      )}
                    </option>
                    {banks &&
                      banks.data.map((item, index) => {
                        return (
                          <option
                            selected={
                              mainData &&
                              mainData.bankDetails &&
                              mainData.bankDetails[0] &&
                              mainData.bankDetails[0].bankName === item.bankName
                            }
                            className={item.bankName}
                          >
                            {item.bankName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col-xl-4 mb-3">
                  <h6 className="mb-0">
                    {getTranslation("branchName", currentLanguage, bankTab)}
                  </h6>
                </div>
                <div className="col-xl-8 mb-3">
                  <input
                    type="text"
                    className="form-control input-default "
                    placeholder={getTranslation(
                      "branchName",
                      currentLanguage,
                      bankTab
                    )}
                    name="bankBranchName"
                    {...register("bankDetails[0].bankBranchName", {
                      required: false,
                      onChange: handleInputChange,
                    })}
                  />
                </div>
                <div className="col-xl-4 mb-3">
                  <h6 className="mb-0">
                    {getTranslation("routingNo", currentLanguage, bankTab)}
                  </h6>
                </div>
                <div className="col-xl-8 mb-3">
                  <input
                    type="text"
                    className="form-control input-default "
                    placeholder={getTranslation(
                      "routingNo",
                      currentLanguage,
                      bankTab
                    )}
                    name="bankRoutingNo"
                    {...register("bankDetails[0].bankRoutingNo", {
                      required: false,
                      onChange: handleInputChange,
                    })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <Button type="submit" className="w-100">
                    {getTranslation("saveButton", currentLanguage, bankTab)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};
export default Bank;
