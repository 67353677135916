import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import BasicInfo from "./BasicInfo";
import Kitchen from "./Kitchen";
import Identity from "./Identity";
import KitchenPhotosVideos from "./KitchenPhotosVideos";
import Cuisines from "./Cuisines";
import AmazingPhotos from "./AmazingPhotos";
import ExperimentalFoods from "./ExperimentalFoods";
import OtherFoodSkills from "./OtherFoodSkills";
import MyRestrictions from "./MyRestrictions";
import TakeOrderEnable from "./TakeOrderEnable";
import Bank from "./Bank";
import MobileBanking from "./MobileBanking";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const editProfileLabels = labels.userDashboard.editProfile;


const EditBasicInfo = () => {
  const [hasSellerData, setHasSellerData] = useState(false);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  // Function to change the language
  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };



  //Tab labels:
  const basicInfoTabTitle = getTranslation(
    "basicInfoTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const basicInfoTabDesc = getTranslation(
    "basicInfoTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );
  const identityTabTitle = getTranslation(
    "identityTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const identityTabDesc = getTranslation(
    "identityTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );
  // const tab = editProfileLabels;

  const kitchenTabTitle = getTranslation(
    "kitchenTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const kitchenTabDesc = getTranslation(
    "kitchenTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const kitchenPhotosVideosTabTitle = getTranslation(
    "kitchenPhotosVideosTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const kitchenPhotosVideosTabDesc = getTranslation(
    "kitchenPhotosVideosTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const cuisinesTabTitle = getTranslation(
    "cuisinesTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const cuisinesTabDesc = getTranslation(
    "cuisinesTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const amazingPhotosTabTitle = getTranslation(
    "amazingPhotosTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const amazingPhotosTabDesc = getTranslation(
    "amazingPhotosTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const experimentalFoodsTabTitle = getTranslation(
    "experimentalPhotosTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const experimentalFoodsTabDesc = getTranslation(
    "experimentalPhotosTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const OtherFoodSkillsTabTitle = getTranslation(
    "otherFoodSkillsTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const OtherFoodSkillsTabDesc = getTranslation(
    "otherFoodSkillsTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const myRestrictionTabTitle = getTranslation(
    "myRestrictionTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const myRestrictionTabDesc = getTranslation(
    "myRestrictionTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const takeOrderEnableTabTitle = getTranslation(
    "takeOrderEnableTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const takeOrderEnableTabDesc = getTranslation(
    "takeOrderEnableTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const bankTabTitle = getTranslation(
    "bankTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const bankTabDesc = getTranslation(
    "bankTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  const mobileBankingTabTitle = getTranslation(
    "mobileBankingTab.tabTitle",
    currentLanguage,
    editProfileLabels
  );
  const mobileBankingTabDesc = getTranslation(
    "mobileBankingTab.tabDesc",
    currentLanguage,
    editProfileLabels
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("UserDetails"));
  
    if (user !== null) {
      const token = localStorage.getItem("Token");
      const headers = token ? { token: `${token}` } : {}; // Set token header
  
      axios
        .get(`${BaseURL}/get-user-details/${user._id}`, { headers }) // Pass headers with token
        .then((res) => {
          var data = res.data.data[0];
          if (data && data.sellerData) {
            setHasSellerData(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  


  return (
    <div className="row">
      <Tab.Container defaultActiveKey="1">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body px-3">
              <Nav
                className="nav nav-tabs border-0"
                id="nav-tab"
                role="tablist"
              >
                <Nav.Link
                  className="nav-link setting-bx d-flex"
                  id="pills-1-tab"
                  eventKey="1"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 6C13.2426 6 14.25 4.99264 14.25 3.75C14.25 2.50736 13.2426 1.5 12 1.5C10.7574 1.5 9.75 2.50736 9.75 3.75C9.75 4.99264 10.7574 6 12 6Z"
                      fill="#3D4152"
                    />
                    <path
                      d="M13.5 6.75H10.5C9.50544 6.75 8.55161 7.14509 7.84835 7.84835C7.14509 8.55161 6.75 9.50544 6.75 10.5V14.25C6.75 14.4489 6.82902 14.6397 6.96967 14.7803C7.11032 14.921 7.30109 15 7.5 15C7.69891 15 7.88968 14.921 8.03033 14.7803C8.17098 14.6397 8.25 14.4489 8.25 14.25V10.5C8.2513 10.0358 8.39616 9.58335 8.6647 9.2047C8.93325 8.82605 9.31234 8.53974 9.75 8.385V21.75C9.75 21.9489 9.82902 22.1397 9.96967 22.2803C10.1103 22.421 10.3011 22.5 10.5 22.5C10.6989 22.5 10.8897 22.421 11.0303 22.2803C11.171 22.1397 11.25 21.9489 11.25 21.75V15.615C11.7331 15.799 12.2669 15.799 12.75 15.615V21.75C12.75 21.9489 12.829 22.1397 12.9697 22.2803C13.1103 22.421 13.3011 22.5 13.5 22.5C13.6989 22.5 13.8897 22.421 14.0303 22.2803C14.171 22.1397 14.25 21.9489 14.25 21.75V8.385C14.6877 8.53974 15.0668 8.82605 15.3353 9.2047C15.6038 9.58335 15.7487 10.0358 15.75 10.5V14.25C15.75 14.4489 15.829 14.6397 15.9697 14.7803C16.1103 14.921 16.3011 15 16.5 15C16.6989 15 16.8897 14.921 17.0303 14.7803C17.171 14.6397 17.25 14.4489 17.25 14.25V10.5C17.25 10.0075 17.153 9.51991 16.9645 9.06494C16.7761 8.60997 16.4999 8.19657 16.1517 7.84835C15.8034 7.50013 15.39 7.22391 14.9351 7.03545C14.4801 6.847 13.9925 6.75 13.5 6.75Z"
                      fill="#3D4152"
                    />
                  </svg>
                  <div className="setting-info">
                    {/* <button onClick={() => changeLanguage('en')}>English</button>
                                    <button onClick={() => changeLanguage('bn')}>বাংলা</button> */}
                    <h6>{basicInfoTabTitle}</h6>
                    <p className="mb-0">{basicInfoTabDesc}</p>
                  </div>
                </Nav.Link>
                {hasSellerData && (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" className="mt-2">
                        Seller Information
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="mt-2 pt-2"
                        style={{ maxHeight: "500px", overflowY: "auto" }}
                      >
                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-12-tab"
                            eventKey="12"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 6C13.2426 6 14.25 4.99264 14.25 3.75C14.25 2.50736 13.2426 1.5 12 1.5C10.7574 1.5 9.75 2.50736 9.75 3.75C9.75 4.99264 10.7574 6 12 6Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M13.5 6.75H10.5C9.50544 6.75 8.55161 7.14509 7.84835 7.84835C7.14509 8.55161 6.75 9.50544 6.75 10.5V14.25C6.75 14.4489 6.82902 14.6397 6.96967 14.7803C7.11032 14.921 7.30109 15 7.5 15C7.69891 15 7.88968 14.921 8.03033 14.7803C8.17098 14.6397 8.25 14.4489 8.25 14.25V10.5C8.2513 10.0358 8.39616 9.58335 8.6647 9.2047C8.93325 8.82605 9.31234 8.53974 9.75 8.385V21.75C9.75 21.9489 9.82902 22.1397 9.96967 22.2803C10.1103 22.421 10.3011 22.5 10.5 22.5C10.6989 22.5 10.8897 22.421 11.0303 22.2803C11.171 22.1397 11.25 21.9489 11.25 21.75V15.615C11.7331 15.799 12.2669 15.799 12.75 15.615V21.75C12.75 21.9489 12.829 22.1397 12.9697 22.2803C13.1103 22.421 13.3011 22.5 13.5 22.5C13.6989 22.5 13.8897 22.421 14.0303 22.2803C14.171 22.1397 14.25 21.9489 14.25 21.75V8.385C14.6877 8.53974 15.0668 8.82605 15.3353 9.2047C15.6038 9.58335 15.7487 10.0358 15.75 10.5V14.25C15.75 14.4489 15.829 14.6397 15.9697 14.7803C16.1103 14.921 16.3011 15 16.5 15C16.6989 15 16.8897 14.921 17.0303 14.7803C17.171 14.6397 17.25 14.4489 17.25 14.25V10.5C17.25 10.0075 17.153 9.51991 16.9645 9.06494C16.7761 8.60997 16.4999 8.19657 16.1517 7.84835C15.8034 7.50013 15.39 7.22391 14.9351 7.03545C14.4801 6.847 13.9925 6.75 13.5 6.75Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{identityTabTitle}</h6>
                              <p className="mb-0">{identityTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-2-tab"
                            eventKey="2"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 12C20.4853 12 22.5 9.98528 22.5 7.5C22.5 5.01472 20.4853 3 18 3C15.5147 3 13.5 5.01472 13.5 7.5C13.5 9.98528 15.5147 12 18 12Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M21.75 21H2.25C2.05109 21 1.86032 20.921 1.71967 20.7803C1.57902 20.6397 1.5 20.4489 1.5 20.25V7.5C1.5 7.30109 1.57902 7.11032 1.71967 6.96967C1.86032 6.82902 2.05109 6.75 2.25 6.75H11.25C11.4489 6.75 11.6397 6.82902 11.7803 6.96967C11.921 7.11032 12 7.30109 12 7.5C12 7.69891 11.921 7.88968 11.7803 8.03033C11.6397 8.17098 11.4489 8.25 11.25 8.25H3V19.5H21V12.75C21 12.5511 21.079 12.3603 21.2197 12.2197C21.3603 12.079 21.5511 12 21.75 12C21.9489 12 22.1397 12.079 22.2803 12.2197C22.421 12.3603 22.5 12.5511 22.5 12.75V20.25C22.5 20.4489 22.421 20.6397 22.2803 20.7803C22.1397 20.921 21.9489 21 21.75 21Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{kitchenTabTitle}</h6>
                              <p className="mb-0">{kitchenTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>
                        <Nav.Link
                          className="nav-link setting-bx d-flex"
                          id="pills-3-tab"
                          eventKey="3"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.0003 22.5C11.8942 22.4995 11.7894 22.4765 11.6928 22.4325C7.55282 20.595 4.79282 17.01 3.44282 11.7675L2.25032 6.93C2.21223 6.77899 2.22209 6.61987 2.27854 6.47473C2.33498 6.32958 2.43521 6.2056 2.56532 6.12L3.12032 5.7525C5.79533 3.97063 8.69586 2.55315 11.7453 1.5375C11.8987 1.48659 12.0644 1.48659 12.2178 1.5375C15.2677 2.5555 18.1682 3.97551 20.8428 5.76L21.3978 6.1275C21.5333 6.20741 21.6402 6.32789 21.7035 6.4719C21.7668 6.61591 21.7831 6.77617 21.7503 6.93L20.5428 11.7675C19.2303 17.0175 16.4628 20.595 12.2928 22.4325C12.2009 22.4745 12.1014 22.4974 12.0003 22.5ZM3.85532 7.08L4.93532 11.4C6.09782 16.0425 8.47532 19.245 12.0003 20.925C15.5253 19.245 17.9028 16.0425 19.0653 11.4075L20.1453 7.0875L20.0328 7.0125C17.5381 5.34834 14.8381 4.01472 12.0003 3.045C9.16549 4.01281 6.46803 5.34389 3.97532 7.005L3.85532 7.08Z"
                              fill="#3D4152"
                            />
                            <path
                              d="M12.4357 1.6425C12.3395 1.57281 12.2282 1.52695 12.1108 1.50869C11.9935 1.49044 11.8735 1.50031 11.7607 1.5375C8.71343 2.55612 5.81545 3.97611 3.14318 5.76L2.58818 6.1275C2.45543 6.20953 2.35157 6.33093 2.29107 6.47477C2.23058 6.61862 2.21646 6.77775 2.25068 6.93L3.45818 11.7675C4.77068 17.0175 7.53818 20.595 11.7082 22.4325C11.8216 22.4805 11.9451 22.4999 12.0677 22.4889C12.1904 22.4779 12.3085 22.4369 12.4115 22.3694C12.5146 22.3019 12.5994 22.2101 12.6586 22.1021C12.7177 21.9941 12.7493 21.8732 12.7507 21.75V2.25C12.7501 2.13102 12.7213 2.01387 12.6665 1.90823C12.6117 1.8026 12.5326 1.71152 12.4357 1.6425Z"
                              fill="#3D4152"
                            />
                          </svg>
                          <div className="setting-info">
                            <h6>{kitchenPhotosVideosTabTitle}</h6>
                            <p className="mb-0">{kitchenPhotosVideosTabDesc}</p>
                          </div>
                        </Nav.Link>
                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-4-tab"
                            eventKey="4"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{cuisinesTabTitle}</h6>
                              <p className="mb-0">{cuisinesTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        {/* <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-5-tab"
                            eventKey="5"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 12C20.4853 12 22.5 9.98528 22.5 7.5C22.5 5.01472 20.4853 3 18 3C15.5147 3 13.5 5.01472 13.5 7.5C13.5 9.98528 15.5147 12 18 12Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M21.75 21H2.25C2.05109 21 1.86032 20.921 1.71967 20.7803C1.57902 20.6397 1.5 20.4489 1.5 20.25V7.5C1.5 7.30109 1.57902 7.11032 1.71967 6.96967C1.86032 6.82902 2.05109 6.75 2.25 6.75H11.25C11.4489 6.75 11.6397 6.82902 11.7803 6.96967C11.921 7.11032 12 7.30109 12 7.5C12 7.69891 11.921 7.88968 11.7803 8.03033C11.6397 8.17098 11.4489 8.25 11.25 8.25H3V19.5H21V12.75C21 12.5511 21.079 12.3603 21.2197 12.2197C21.3603 12.079 21.5511 12 21.75 12C21.9489 12 22.1397 12.079 22.2803 12.2197C22.421 12.3603 22.5 12.5511 22.5 12.75V20.25C22.5 20.4489 22.421 20.6397 22.2803 20.7803C22.1397 20.921 21.9489 21 21.75 21Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{amazingPhotosTabTitle}</h6>
                              <p className="mb-0">{amazingPhotosTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-6-tab"
                            eventKey="6"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{experimentalFoodsTabTitle}</h6>
                              <p className="mb-0">{experimentalFoodsTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item> */}

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-7-tab"
                            eventKey="7"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{OtherFoodSkillsTabTitle}</h6>
                              <p className="mb-0">{OtherFoodSkillsTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-8-tab"
                            eventKey="8"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{myRestrictionTabTitle}</h6>
                              <p className="mb-0">{myRestrictionTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-9-tab"
                            eventKey="9"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{takeOrderEnableTabTitle}</h6>
                              <p className="mb-0">{takeOrderEnableTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-10-tab"
                            eventKey="10"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{bankTabTitle}</h6>
                              <p className="mb-0">{bankTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Nav.Link
                            className="nav-link setting-bx d-flex"
                            id="pills-11-tab"
                            eventKey="11"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51472 4.5 7.5 6.51472 7.5 9C7.5 11.4853 9.51472 13.5 12 13.5Z"
                                fill="#3D4152"
                              />
                              <path
                                d="M16.5 19.5C16.351 19.5009 16.2052 19.4573 16.081 19.375C15.9569 19.2926 15.8601 19.1752 15.8029 19.0376C15.7458 18.9 15.731 18.7485 15.7603 18.6025C15.7896 18.4564 15.8617 18.3224 15.9675 18.2175L20.4675 13.7175C20.7238 13.4479 20.8955 13.1092 20.9615 12.7431C21.0276 12.3771 20.985 11.9997 20.839 11.6575C20.6931 11.3154 20.4501 11.0235 20.1402 10.8178C19.8303 10.6121 19.4669 10.5016 19.095 10.5H15.675C15.4761 10.5 15.2853 10.421 15.1446 10.2803C15.004 10.1397 14.925 9.94891 14.925 9.75C14.925 9.55109 15.004 9.36032 15.1446 9.21967C15.2853 9.07902 15.4761 9 15.675 9H19.095C19.7684 9.00008 20.4266 9.19982 20.9865 9.57397C21.5464 9.94812 21.9827 10.4799 22.2404 11.102C22.4981 11.7241 22.5655 12.4087 22.4342 13.0692C22.3028 13.7296 21.9786 14.3363 21.5025 14.8125L17.0025 19.3125C16.8638 19.4348 16.6849 19.5016 16.5 19.5ZM7.49998 19.5C7.40127 19.5006 7.30343 19.4817 7.21205 19.4443C7.12067 19.407 7.03756 19.352 6.96748 19.2825L2.46748 14.7825C1.99978 14.303 1.6839 13.6962 1.55936 13.038C1.43483 12.3799 1.50719 11.6996 1.76737 11.0824C2.02755 10.4652 2.464 9.93838 3.0221 9.56797C3.5802 9.19757 4.23515 9 4.90498 9H8.32498C8.52389 9 8.71466 9.07902 8.85531 9.21967C8.99596 9.36032 9.07498 9.55109 9.07498 9.75C9.07498 9.94891 8.99596 10.1397 8.85531 10.2803C8.71466 10.421 8.52389 10.5 8.32498 10.5H4.90498C4.52952 10.5018 4.16296 10.6145 3.85135 10.8239C3.53973 11.0334 3.29696 11.3302 3.15352 11.6772C3.01008 12.0242 2.97237 12.4058 3.04513 12.7742C3.11789 13.1425 3.29788 13.4811 3.56248 13.7475L8.06248 18.2475C8.16001 18.3556 8.22399 18.4897 8.24661 18.6334C8.26924 18.7772 8.24953 18.9245 8.1899 19.0573C8.13027 19.1901 8.03328 19.3026 7.91078 19.3812C7.78827 19.4599 7.64553 19.5011 7.49998 19.5Z"
                                fill="#3D4152"
                              />
                            </svg>
                            <div className="setting-info">
                              <h6>{mobileBankingTabTitle}</h6>
                              <p className="mb-0">{mobileBankingTabDesc}</p>
                            </div>
                          </Nav.Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </Nav>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="row">
            <div className="col-12">
              <Tab.Content>
                <BasicInfo />
                {hasSellerData && (
                  <>
                    <Identity />

                    <Kitchen />

                    <KitchenPhotosVideos />

                    <Cuisines />

                    <AmazingPhotos />

                    <ExperimentalFoods />

                    <OtherFoodSkills />

                    <MyRestrictions />

                    <TakeOrderEnable />

                    <Bank />

                    <MobileBanking />
                  </>
                )}
              </Tab.Content>
            </div>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};
export default EditBasicInfo;
