import { BaseURL } from "../../../Helper/config";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";

const CreateCouponForSeller = () => {
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const { handleSubmit, control, register, watch, reset } = useForm();
  const [sellerID, setSellerID] = useState(null);

  // State for coupon type (All Products or Specific Products)
  const [couponType, setCouponType] = useState("all");

  useEffect(() => {
    // Retrieve and parse local storage data
    const userData = JSON.parse(localStorage.getItem("UserDetails"));
    if (userData && userData.sellerIDInfo) {
      const seller = userData.sellerIDInfo.find(
        (info) => info.sellerApproval === "Approved"
      );
      if (seller) {
        setSellerID(seller._id);
      }
    }
  }, []);

  useEffect(() => {
    if (sellerID) {
      const token = localStorage.getItem("Token");
      axios
        .get(`${BaseURL}/get-food-by-seller/${sellerID}/10`, {
          headers: { token },
        })
        .then((res) => {
          const fetchedProducts = res.data.data.flatMap((item) => item.items);
          setProducts(fetchedProducts);
        })
        .catch((err) => console.error("Error fetching products:", err));
    }
  }, [sellerID]);

  // Fetch all users
  useEffect(() => {
    const token = localStorage.getItem("Token");
    axios
      .get(`${BaseURL}/get-users`, { headers: { token } })
      .then((res) => setUsers(res.data.data))
      .catch((err) => console.error("Error fetching users:", err));
  }, []);

  const onSubmit = (data) => {
    const couponData = {
      sellerID,
      isCouponForAllProduct: couponType === "all", // Coupon for all products or not
      couponForProductID: couponType === "specific" ? data.couponForProductID?.map((p) => p.value) : [],
      couponCode: data.couponCode,
      couponAmount: data.couponAmount,
      couponIsPercentage: data.isCouponPercentage,
      couponStartDate: data.couponStartDate,
      couponEndDate: data.couponEndDate,
      couponLimit: data.couponLimit,
      couponMinPurchase: data.couponMinPurchase,
      isSeller:true
      // New field added
    };

    axios
      .post(`${BaseURL}/create-coupon`, couponData, {
        headers: { token: localStorage.getItem("Token") },
      })
      .then((res) => {
        toast.success("Coupon created successfully!");
        reset();
      })
      .catch((err) => {
        console.error("Error creating coupon:", err);
        toast.error("Failed to create coupon.");
      });
  };

  return (
    <div className="container mt-4">
      <h3>Create Coupon for Seller</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Radio buttons for choosing coupon type */}
        <div className="form-check mb-3">
          <input
            type="radio"
            id="allProducts"
            name="couponType"
            value="all"
            checked={couponType === "all"}
            onChange={() => setCouponType("all")}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="allProducts">
            Coupon for All Products
          </label>
        </div>
        <div className="form-check mb-3">
          <input
            type="radio"
            id="specificProducts"
            name="couponType"
            value="specific"
            checked={couponType === "specific"}
            onChange={() => setCouponType("specific")}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="specificProducts">
            Coupon for Specific Products
          </label>
        </div>

        {/* Product selection for specific products */}
        {couponType === "specific" && (
          <div className="mb-3">
            <label>Select Products</label>
            <Controller
              name="couponForProductID"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={products.map((p) => ({
                    value: p._id,
                    label: p.foodName || "Unnamed Product",
                  }))}
                  isMulti
                  placeholder="Select products"
                />
              )}
            />
          </div>
        )}

        {/* Coupon code */}
        <div className="mb-3">
          <label>Coupon Code</label>
          <input
            type="text"
            className="form-control"
            {...register("couponCode", { required: true })}
          />
        </div>

        {/* Coupon amount */}
        <div className="mb-3">
          <label>Coupon Amount</label>
          <input
            type="number"
            className="form-control"
            {...register("couponAmount", { required: true })}
            min={0}
          />
        </div>

        {/* Is coupon percentage */}
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="isCouponPercentage"
            {...register("isCouponPercentage")}
          />
          <label className="form-check-label" htmlFor="isCouponPercentage">
            Is Coupon Percentage?
          </label>
        </div>

        {/* Coupon limit */}
        <div className="mb-3">
          <label>Coupon Limit</label>
          <input
            type="number"
            className="form-control"
            min={0}
            {...register("couponLimit", { required: true })}
          />
        </div>

        {/* Minimum purchase amount */}
        <div className="mb-3">
          <label>Minimum Purchase Amount</label>
          <input
            type="number"
            className="form-control"
            min={0}
            {...register("couponMinPurchase", { required: true })}
          />
        </div>

        {/* Start date */}
        <div className="mb-3">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control"
            min={new Date().toISOString().split("T")[0]}
            {...register("couponStartDate", { required: true })}
          />
        </div>

        {/* End date */}
        <div className="mb-3">
          <label>End Date</label>
          <input
            type="date"
            min={watch("couponStartDate") || new Date().toISOString().split("T")[0]}
            className="form-control"
            {...register("couponEndDate", { required: true })}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Create Coupon
        </button>
      </form>
    </div>
  );
};

export default CreateCouponForSeller;
