import React, { Fragment, useState } from "react";

import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
//import RightSideBar from "./RightSideBar";
// import ChatBox from "../ChatBox";

const MainNavSide = ({ title, onClick: ClickToAddEvent, onClick2, onClick3,setToggle,toggle }) => {
  const [toggle1, settoggle1] = useState("");
  const onClick = (name) => settoggle1(toggle1 === name ? "" : name);
  return (
    <Fragment>

      <NavHader  toggle={toggle} setToggle={setToggle}/>
      {/* <ChatBox onClick={() => onClick("chatbox")} toggle1={toggle1} /> */}
      <Header

          onNote={() => onClick("chatbox")}
          onNotification={() => onClick("notification")}
          onProfile={() => onClick("profile")}
          toggle={toggle1}
          title={title}
          onBox={() => onClick("box")}
          onClick={() => ClickToAddEvent()}
        />
      <SideBar />

    </Fragment>
  );
};

export default MainNavSide;
