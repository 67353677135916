// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCyWNRqpEx3dIOQasbCs7mhD7uJFjCa_8M",
  authDomain: "ownfood-514c2.firebaseapp.com",
  databaseURL:
    "https://ownfood-514c2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ownfood-514c2",
  storageBucket: "ownfood-514c2.appspot.com",
  messagingSenderId: "291864454179",
  appId: "1:291864454179:web:16344e714efce9b3abfd96",
  measurementId: "G-Q9JMM6X2X9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
