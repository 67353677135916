import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';

import {Provider} from 'react-redux';
// import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext";
import routes from './routes/routes';
import store from './Redux/store';
import { initiate } from './Helper/crossStorage';
import { LanguageContextProvider } from './context/LanguageContext';
initiate();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
      <Provider store={store}>
        
        <ThemeContext>
        <LanguageContextProvider>
          <App />
          </LanguageContextProvider>
        </ThemeContext>
        
        </Provider>

  </React.StrictMode>
);


