// import { toast } from "react-hot-toast";
import { ToastContainer, toast } from 'react-toastify';
import { BaseURL } from "../Helper/config";
import axios from "axios";
import { getAllUsers, getUserDetails } from "../Redux/feature/Users/UsersSlice";
import store from "../Redux/store";
// const { default: axios } = require("axios");
// const { default: store } = require("../Redux/store");
// const { getAllUsers } = require("../Redux/feature/Users/UsersSlice");
import Swal from 'sweetalert2'

import { setEmail, setMobile } from "../Helper/SessionHelper";
import swal from "sweetalert";

// const MySwal = withReactContent(Swal)



export const GetAllUser = async () => {
  try {
    const URL = `${BaseURL}/get-users`;

    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token } : {};

    // Axios GET request with token in headers
    const res = await axios.get(URL, { headers });

    if (res.status === 200 && res.data["status"] === "Success") {
      store.dispatch(getAllUsers(res.data.data));
      return true;
    } else {
      toast.error("Something went wrong! GetAllUser -1");
      return false;
    }
  } catch (e) {
    toast.error("Something went wrong! GetAllUser -2");
    return false;
  }
};


export const userDetailsAPI = async (id) => {
  try {
    const URL = `${BaseURL}/get-user-details/${id}`;

    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token } : {};

    // Axios GET request with token in headers
    const res = await axios.get(URL, { headers });

    if (res.status === 200 && res.data["status"] === "Success") {
      store.dispatch(getUserDetails(res.data.data));
      return true;
    } else {
      toast.error("Something went wrong! userDetailsAPI -1");
      return false;
    }
  } catch (e) {
    toast.error(e.message);
    return false;
  }
};


//! =================== Step One Recovery Verify Email ===================

// Step One Recovery Verify Email
export const RecoveryVerifyEmail = async (email) => {
  try {
    const URL = `${BaseURL}/forget-password-verify-email/${email}`;

    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token } : {};

    // Axios POST request with token in headers
    const res = await axios.post(URL, {}, { headers });

    if (res.status === 200) {
      if (res.data["status"] === "Fail") {
        Swal.fire({
          title: "⚠️",
          text: res.data.data,
          timer: 1500,
          icon: "error",
        });
        return false;
      } else {
        setEmail(email);
        toast.success("A 4-digit verification code has been sent to your email.");
        return true;
      }
    } else {
      toast.error("Something wrong RecoveryVerifyEmailAPI -1");
      return false;
    }
  } catch (e) {
    toast.error("Something wrong RecoveryVerifyEmailAPI -2");
    return false;
  }
};


// Step One Recovery Verify Mobile number
export const RecoveryVerifyMobile = async (mobile) => {
  try {
    const URL = `${BaseURL}/forget-password-verify-mobile/${mobile}`;

    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token } : {};

    // Axios POST request with token in headers
    const res = await axios.post(URL, {}, { headers });

    if (res.status === 200) {
      if (res.data["status"] === "Fail") {
        Swal.fire({
          title: "⚠️",
          text: res.data.data,
          timer: 1500,
          showConfirmButton: false,
          icon: "error",
        });
        return false;
      } else {
        setMobile(mobile);
        Swal.fire({
          title: "A 4-digit verification code has been sent to your mobile number ✔️",
          showConfirmButton: false,
          timer: 1500,
          icon: "success",
        });
        return true;
      }
    } else {
      toast.error("Something wrong RecoveryVerifyMobileAPI -1");
      return false;
    }
  } catch (e) {
    toast.error("Something wrong RecoveryVerifyMobileAPI -2");
    return false;
  }
};

