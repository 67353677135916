import { createSlice } from "@reduxjs/toolkit";

const CategorySlice = createSlice({
    name:'category',
    initialState:{
      AllCategoriesList:[]
    },
    reducers:{
        AllCategory:(state,action)=>{
            state.AllCategoriesList=action.payload
        }

    }
})

export const { AllCategory} = CategorySlice.actions
export default CategorySlice.reducer