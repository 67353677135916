
// /// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Main from "./jsx/layouts/Main";
import { BrowserRouter, Route, RouterProvider, Routes, useNavigate } from "react-router-dom";
import routes from "./routes/routes";

import { getToken, getUserDetails } from "./Helper/SessionHelper";
import Login from "./jsx/Authentication/Login";
import { ToastContainer } from "react-toastify";
import { LanguageContext } from "./context/LanguageContext";
function App () {
  // const isAuthenticate = getToken() && getUserDetails()

 // const navigate = useNavigate();

  // if (!isAuthenticate) {
  //   navigate("/login");
  // }

  return (
    <>
    
    <ToastContainer />
<RouterProvider router={routes}>

</RouterProvider>

    </>
  )



};

export default App