// Create a new component for location tracking
import { ref, set } from "firebase/database";
import React, { useEffect } from "react";
import { database } from "../../Helper/firebase";

const LocationTracker = ({ riderId }) => {
  useEffect(() => {
    let watchId;
    if (!riderId) {
      console.error("riderId is undefined or null", riderId);
      return;
    }
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          //   firebase.database().ref(`riders/${riderId}/location`).set(location);
          console.log(riderId);
          const driverRef = ref(database, `drivers/${riderId}`);
          set(driverRef, {
            ...location,
            timestamp: Date.now(),
            // orderId: orderId,
            riderId: riderId,
          });
        },
        (error) => {
          console.error("Error getting location: ", error);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [riderId]);

  return null;
};

export default LocationTracker;
