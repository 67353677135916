import React from 'react';
import { getToken, getUserDetails } from '../Helper/SessionHelper';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRouter = ({children}) => {
    // const location = useLocation();
    const isAuthenticate =  getUserDetails()
    // console.log("hi test 0", isAuthenticate);
    if (isAuthenticate === "undefined") {
      // console.log("hi test 1");
      return <Navigate to='/login' replace></Navigate>;
      //   return children;
    } else if (!isAuthenticate) {
      // console.log("hi test 2");
      //   if (isAuthenticate === "undefined") {
      // console.log("hi test 3");
      return <Navigate to='/login' replace></Navigate>;
      //   }
    } else if (isAuthenticate === "") {
      return <Navigate to='/login' replace></Navigate>;
    } else {
      return children;
    }
};

export default PrivateRouter;