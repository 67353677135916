import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { BaseURL } from "../../../Helper/config";
import { getUserDetails } from "../../../Helper/SessionHelper";
import PageTitle from "../../layouts/PageTitle";

function dateFormat(value) {
  let dateData = new Date(value);
  // Format the date as dd/mm/yyyy
  const formattedDate = dateData.toLocaleDateString("bn-BD"); // 'bn-BD' for the Bengali language in Bangladesh
  return formattedDate;
}

const FoodList = () => {
  const [foodData, setFoodData] = useState();
  const sellerID = getUserDetails()?.sellerIDInfo[0]?._id;
  // const chackboxFun = (type) => {
  //   setTimeout(() => {
  //     const checkbox = document.querySelectorAll(".application_sorting_1 input");
  //     const motherCheckBox = document.querySelector(".sorting_asc input");
  //     for (let i = 0; i < checkbox.length; i++) {
  //       const element = checkbox[i];
  //       if (type === "all") {
  //         if (motherCheckBox.checked) {
  //           element.checked = true;
  //         } else {
  //           element.checked = false;
  //         }
  //       } else {
  //         if (!element.checked) {
  //           motherCheckBox.checked = false;
  //           break;
  //         } else {
  //           motherCheckBox.checked = true;
  //         }
  //       }
  //     }
  //   }, 200);
  // };

  useEffect(() => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};  // Set token header
  
    axios
      .get(`${BaseURL}/get-food-seller-profile/${sellerID}`, { headers })
      .then((res) => {
        setFoodData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);  // Empty dependency array to run once on mount
  
  const handleDelete = (data) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};  // Set token header
  
    axios
      .delete(`${BaseURL}/delete-food/${data}`, { headers })
      .then((res) => {
        console.log(res);
        const newData = foodData.filter((item) => item._id !== data);
        setFoodData(newData);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  
  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your food has been deleted.", "success");
      }
    });
  };
  return (
    <>
      <PageTitle activeMenu="আমার খাবার" motherMenu="খাবার" />
      <Card>
        <Card.Header>
          <Card.Title>আমার খাবার</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <strong>FoodID</strong>
                </th>
                <th>
                  <strong>Type</strong>
                </th>
                <th>
                  <strong>Photo</strong>
                </th>
                <th>
                  <strong>Dish Name</strong>
                </th>
                <th>Category</th>

                <th>
                  <strong>Date</strong>
                </th>
                <th>
                  <strong>Status</strong>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {foodData?.length>0 && foodData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.foodID}</td>
                    <td>{item?.foodType}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          className="rounded-lg me-2"
                          src={item?.foodImage[0]?.orginalImageUrl}
                          width="100"
                          alt="images"
                        />
                      </div>
                    </td>
                    <td>{item?.foodName}</td>
                    <td>{item?.foodCategory}</td>
                    <td>{dateFormat(item?.createdDate)}</td>
                    <td>
                      {item.status === 1 ? (
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Published
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-danger me-1"></i>{" "}
                          Unpublished
                        </div>
                      )}
                    </td>

                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/edit-food/${item._id}`}
                          state={item}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </Link>

                        <div>
                          <Link
                            to={"#"}
                            onClick={() => {
                              del(item._id);
                            }}
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default FoodList;
