import axios from "axios";
import Swal from "sweetalert2";
import { BaseURL } from "../../Helper/config";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { LanguageContext } from "../../context/LanguageContext";
import labels from "../../translationData/editProfile.json";
import getTranslation from "../../Helper/getTranslationUtility";
import { getUserDetails } from "../../Helper/SessionHelper";
const cresellerWithdraw = labels.userDashboard.sellerWithdraw;


const CreateSellerWithdraw = () => {
  const [withdrawData, setWithdrawData] = useState([]);
  const [accountType, setAccountType] = useState("BANK_ACCOUNT");
  const [sellerData, setSellerData] = useState({});
  const [operatorName, setOperatorName] = useState("");
  const [totalCredit, setTotalCredit] = useState(0);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const [selectedWithdraw, setSelectedWithdraw] = useState([]);
  const [mobileBankData, setMobileBankData] = useState({});

  const GetWithdrawAbleOrder = async (id) => {
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {}; // Set token header

    try {
      const res = await axios.get(`${BaseURL}/withdrawable-order-seller/${id}`, { headers });
      if (res.data.status === "Success") {
        setWithdrawData(res.data.data);
        setSellerData(res.data.sellerData);
      }
    } catch (err) {
      console.log(err, "Error fetching withdrawable orders");
    }
  };



  useEffect(() => {
    GetWithdrawAbleOrder(getUserDetails()?.sellerIDInfo[0]?._id);
  }, []);
  const { id } = useParams();

  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm();
  // console.log(withdrawData, "check-withdraw");
  const [loading, setLoading] = useState(false);
  // console.log(sellerData, "sellerData");

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    let bankAccName;
    let bankAccNo;
    let bankName;
    let bankRoutingNo;
    if (accountType === "BANK_ACCOUNT") {
      bankAccName = form.bankAccName.value;
      bankAccNo = form.bankAccNo.value;
      bankName = form.bankName.value;
      bankRoutingNo = form.bankRoutingNo.value;
    }

    let BankData = { bankAccName, bankAccNo, bankName, bankRoutingNo };
    // console.log(BankData, "form-data");
    let withdrawData = {
      withdrwalAmount: totalCredit,
      balanceDuringWithrawal: totalCredit,
      // bankAccountDetails: accountType == "bank" && BankData,
      // mobileBankingAccountDetails: accountType == "mobile" &&mobileBankData,
      withdrwalPaymentMethod: accountType,
      userId: sellerData._id,
      userType: "seller",
      orderID: selectedWithdraw,
      // adminID: ,
      // transactionId:,
      // transactionDetails: [{}],
      // currency: { type: String }, // bdt
      // rejectReasons: { type: Array },
      // status: { type: Number, default: 0 },
      // completedDate: ,
      // createdBy: , //created by user
      // createdDate: ,
      // updatedBy: , //updated by admin
      // updatedDate:
    };
    if (accountType === "BANK_ACCOUNT") {
      withdrawData.bankAccountDetails = BankData;
    } else if (accountType === "MOBILE_BANKING") {
      withdrawData.mobileBankingAccountDetails = mobileBankData;
    }

    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {}; // Set token header

    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);

    try {
      const response = await axios.post(
        `${BaseURL}/create-withdrawal`,
        withdrawData,
        {
          headers,
          signal,
        }
      );
      console.log(signal, "debug-plan", response.data.status);
      if (response.data.status == "Success") {
        Swal.fire({
          icon: "success",
          title: "Wait for admin approval",
          timer: 3000,
        });
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error posting data:", error);
        setLoading(false);
      }
    }
  };


  const handleCheckboxChange = (id, isChecked) => {
    const updatedWithdrawData = withdrawData.map((item) => {
      if (item.id === id) {
        item.checked = isChecked;
        // if (item.isChecked==true) {
        // console.log(item, "c");
        // setSelectedWithdraw([...selectedWithdraw, item]);
        // }
        // setTotalCredit((totalCredit += item.credit));
      }
      return item;
    });
    // setSelectedWithdraw([updatedWithdrawData]);
    const selectedItems = updatedWithdrawData
      .filter((item) => item.checked)
      .map((item) => ({ orderId: item.orderId }));
    setSelectedWithdraw(selectedItems);
    setWithdrawData(updatedWithdrawData);
    let updatedTotal = 0;

    updatedTotal = updatedWithdrawData.reduce((acc, item) => {
      if (item.checked) {
        acc += item.credit;
      }
      return acc;
    }, 0);
    setTotalCredit(updatedTotal);
  };
  const handleMobileBank = (e) => {
    const { operatorName } = JSON.parse(e.target.value);
    // const {} = JSON.parse(e.target.value)
    setMobileBankData(JSON.parse(e.target.value));
    // console.log(JSON.parse(e.target.value), operatorName, "check-event");
    setOperatorName(operatorName);
  };
  // console.log(selectedWithdraw, "c");

  // Function to change the language
  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  //Tab labels:
  const withdraw = getTranslation(
    "withdraw.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const buttonWithdraw = getTranslation(
    "buttonWithdraw.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );

  const sl = getTranslation(
    "sl.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );

  const txnid = getTranslation(
    "txnid.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const Orderid = getTranslation(
    "Orderid.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const amount = getTranslation(
    "amount.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const Withdrawal = getTranslation(
    "Withdrawal.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const bank = getTranslation(
    "bank.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const methods = getTranslation(
    "methods.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const mobile = getTranslation(
    "mobile.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const accountName = getTranslation(
    "accountName.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const accountNumber = getTranslation(
    "accountNumber.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const BankName = getTranslation(
    "BankName.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );
  const bankRoutingNumber = getTranslation(
    "bankRoutingNumber.tabTitle",
    currentLanguage,
    cresellerWithdraw
  );


  return (
    <div>
      <div className='container d-flex '>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>{withdraw}</h4>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                {
                  <table
                    className='table table-list i-table style-1 mb-4 border-0'
                    id='guestTable-all3'
                  >
                    <thead>
                      <tr>
                        <th>{sl}</th>
                        <th>{txnid}</th>
                        <th>{Orderid}</th>
                        <th>{amount}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className='media-bx d-flex py-3 align-items-center'>
                              <div>
                                {" "}
                                <input
                                  type='checkbox'
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      item.id,
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='media-bx d-flex py-3 align-items-center'>
                              <div>
                                {item?.id}
                                {/* <p className="mb-0">1x </p> */}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='media-bx d-flex py-3 align-items-center'>
                              <div>
                                {`Earning from Food Sell OrderID#${item.orderId}`}
                                {/* <p className="mb-0">1x </p> */}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div>
                              <span className='btn btn-primary m-1 p-2'>
                                {item.credit}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={3}>
                          <h4>{Withdrawal}</h4>
                        </td>
                        <td>
                          <div className='bg-success border rounded-sm d-flex justify-content-center align-items-center'>
                            <div className='py-1'>
                              <h3 className='text-white font-w700 p-0 m-0'>
                                TK {totalCredit}
                              </h3>{" "}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                }
              </div>
              <div>
                <div className='form-group mb-3'>
                  <label className='text-black'>
                    {methods}
                  </label>

                  <select
                    // {...register("accountType", {
                    //   required: true,
                    // })}
                    onChange={(e) => setAccountType(e.target.value)}
                    name='accountType'
                    className='form-control input-default'
                  >
                    <option defaultChecked value='BANK_ACCOUNT'>
                      {bank}
                    </option>
                    <option value='MOBILE_BANKING'>{mobile}</option>
                  </select>
                </div>
              </div>

              <div className='basic-form'>
                <form onSubmit={onSubmit}>
                  {accountType === "MOBILE_BANKING" && (
                    <div className='row'>
                      <div className='col-12'>
                        <div className='form-group mb-3'>
                          <label>{mobile}</label>
                          <div>
                            {sellerData?.mobileBanking &&
                              sellerData?.mobileBanking.map((item, index) => (
                                <div key={index} className='form-check'>
                                  <input
                                    type='radio'
                                    id={`mobile_${index}`}
                                    name='selectedMobile'
                                    value={JSON.stringify(item)}
                                    onChange={(e) => handleMobileBank(e)}
                                    className='form-check-input'
                                  />
                                  <label
                                    htmlFor={`mobile_${index}`}
                                    className='form-check-label'
                                  >
                                    <div className='d-flex gap-5'>
                                      <div>{item.operatorName || "bkash"}</div>
                                      <div>{item.mobileNumber}</div>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                        {/* <div className='form-group mb-3'>
                          <label>Mobile Number</label>
                          <select
                            // {...register("currency", {
                            //   required: true,
                            // })}
                            onChange={(e) => handleMobileBank(e)}
                            className='form-control input-default'
                          >
                            <option>select mobile</option>
                            {sellerData?.mobileBanking &&
                              sellerData?.mobileBanking.map((item) => (
                                <option value={JSON.stringify(item)}>
                                  <div>
                                    {item.mobileNumber}|{item.operatorName}
                                  </div>
                                </option>
                              ))}
                          </select>
                        </div> */}
                      </div>
                      <div className='col-12 col-md-6 col-xl-6 col-xxl-6 col-lg-6 col-sm-12'>
                        {" "}
                      </div>
                    </div>
                  )}
                  {accountType === "BANK_ACCOUNT" && (
                    <div className='row text-black'>
                      <div className='col-12'>
                        <div className='d-flex justify-content-start row'>
                          <div className='col-6'>{accountName}: </div>
                          <div className='col-6'>
                            <input
                              type='text'
                              className='form-control border-0'
                              value={
                                sellerData?.bankDetails &&
                                sellerData?.bankDetails[0]?.bankAccName
                              }
                              name='bankAccName'
                            // placeholder={inputLabels.accountName.title.bn}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='d-flex justify-content-start row'>
                          <div className='col-6'>{accountNumber} :</div>
                          <div className='col-6'>
                            <input
                              type='text'
                              className='form-control border-0'
                              value={
                                sellerData?.bankDetails &&
                                sellerData?.bankDetails[0]?.bankAccNo
                              }
                              name='bankAccNo'
                            // placeholder={inputLabels.accountName.title.bn}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='d-flex justify-content-start row'>
                          <div className='col-6'>{BankName} :</div>
                          <div className='col-6'>
                            <input
                              type='text'
                              className='form-control border-0'
                              value={
                                sellerData?.bankDetails &&
                                sellerData?.bankDetails[0]?.bankName
                              }
                              name='bankName'
                            // placeholder={inputLabels.accountName.title.bn}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='d-flex justify-content-start row'>
                          <div className='col-6'>{bankRoutingNumber} :</div>
                          <div className='col-6'>
                            <input
                              type='text'
                              className='form-control border-0'
                              value={
                                sellerData?.bankDetails &&
                                sellerData?.bankDetails[0]?.bankRoutingNo
                              }
                              name='bankRoutingNo'
                            // placeholder={inputLabels.accountName.title.bn}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-primary w-100'
                      type='submit'
                      disabled={loading}
                    >
                      {loading ? "Loading...." : `${buttonWithdraw}`}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSellerWithdraw;
