import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const UploaderModel = ({ loader }) => {
  const [show, setShow] = useState(false);
  let percentage = useSelector((state) => state.ImageLoader.percentage);
  useEffect(() => {
    if (percentage > 0 && loader === true) {
      setShow(true);
    } else if (percentage === 100 || loader === false) {
      setShow(false);
    }
    else if(loader===false){
      setShow(false);

    }
  }, [loader, percentage]);

  const handleClose = () => setShow(false);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`Uploading ${percentage}%`} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div class='progress ' style={{ height: "20px" }}>
            <div
              class='progress-bar progress-bar-striped'
              role='progressbar'
              style={{ width: `${percentage}%` }}
              aria-valuenow='0'
              aria-valuemin='0'
              aria-valuemax='100'
            >
          {  ` ${percentage} %   loading...... `}
            </div>
          </div>
        </Modal.Body>
      
      </Modal>
    </div>
  );
};

export default UploaderModel;
