// LandingPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate a delay (you can replace this with your own logic)
    setTimeout(() => {
      // Redirect to the desired page (e.g., '/dashboard')
      navigate('/');
    }, 3000); // Redirect after 3 seconds
  }, [navigate]);

  return (
    <div>
      <h1>Wait a moment till the Dashboard is loading...</h1>
      <p></p>
    </div>
  );
};


export default LandingPage2;

