import { createSlice } from "@reduxjs/toolkit";

const ImageLoaderSlice = createSlice({
    name:"imageUploader",
    initialState:{
        loading:false,
        percentage:0
    },
    reducers:{
        setLoading:(state,action)=>{
            state.loading = action.payload

        },
        setPercentage:(state,action)=>{
            state.percentage = action.payload
        }
    }
})

export default ImageLoaderSlice.reducer
export const {setLoading,setPercentage} = ImageLoaderSlice.actions