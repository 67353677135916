// LandingPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {

    setTimeout(() => {


      navigate('/');
    }, 3000);

      
  }, [navigate]);

  return (
    <div>
      <h1>Wait a moment till the Dashboard is loading...</h1>
      <p></p>
    </div>
  );
};

export default LandingPage;


