import axios from "axios";
import { BaseURL } from "../Helper/config";
import { toast } from "react-hot-toast";
import store from "../Redux/store";
import { GetFoodBySeller } from "../Redux/feature/Seller/SellerSlice";

export const GetFoodBySellerApi = async (id, limit) => {
  try {
    const URL = `${BaseURL}/get-food-by-seller/${id}/${limit}`;
    
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};
 



    const res = await axios.get(URL, { headers });

    if (res.status === 200 && res.data["status"] === "Success") {
      store.dispatch(GetFoodBySeller(res.data.data));
      return true;
    } else {
      toast.error("Something went wrong! GetFoodBySellerApi -1");
      return false;
    }
  } catch (e) {
    toast.error("Something went wrong! GetFoodBySellerApi -2");
    return false;
  }
};
