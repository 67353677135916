import { createSlice } from "@reduxjs/toolkit";

const UsersSlice = createSlice({
  name: "users",
  initialState: {
    allUsers: [],
    userDetails: [],
  },
  reducers: {
    getAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    getUserDetails: (state, action) => {
      console.log(state, "from redux",action);
      state.userDetails = action.payload;
    },
  },
});

export const { getAllUsers, getUserDetails } = UsersSlice.actions;
export default UsersSlice.reducer;
