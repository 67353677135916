import React from 'react';
import { Button } from 'react-bootstrap';
// import ImageUploader from '../../ImageUploader/ImageUploader';
import { getUserDetails } from '../../Helper/SessionHelper';
import { useUpdateSellerMutation } from '../../Redux/API/ApiSlice';
// import { Toast } from 'react-toastify/dist/components';
import { toast } from 'react-toastify';
import { ImageUploader } from '../../ImageUploader/ImageUploader';

const Profile = () => {
    const [updateData,{isSuccess,isError}] = useUpdateSellerMutation()
    const handleProfile=async(e)=>{
        e.preventDefault();
        const form = e.target
      const profileImage=  form.userProfile?.files[0]

       const data= await ImageUploader(getUserDetails(),"Profile",profileImage)
        if (data.status === "Success") {

            console.log('from 1st',data.images);
            updateData({id:getUserDetails().sellerIDInfo[0]._id,data:{
                sellerProfilePhoto:data.images
            }})
            toast.success('image upload sucessfull')
          } else {
            toast.warning('SOMETHING WRONG TRY AGAIN')
            // setIsFileUploading(false);
          }
    }
    return (
        <div>
            <form onSubmit={handleProfile}>
                <input type='file' name='userProfile' />
                <Button type='submit' className='mt-3'>upload</Button>
            </form>

        </div>
    );
};

export default Profile;