import { FrontEndLink } from "../Helper/config";
var initiate = function () {
  var createGuest = require("cross-domain-storage/guest");
  var bazStorage = createGuest(FrontEndLink);
  var previousUserDetails = null;
  function fetchDataDomain() {
    bazStorage.get("UserDetails", function (error, value) {
      console.log(value, "value set from bazStorage");
      if (error) {
        console.log(error);
      } else {
        if (value !== previousUserDetails) {
          console.log("value set from bazStorage", value);
          localStorage.setItem("UserDetails", value);
          previousUserDetails = value; // Update the previous value
        }
      }
    });

    bazStorage.get("Token", function (error, value) {
      if (error) {
        console.log(error);
      } else {
        localStorage.setItem("Token", value);
      }
    });
  }

  fetchDataDomain();
};

export { initiate };
