export const tags = [
 
    {
      name: 'SellerOrders',
      tag: 'seller-order',
    },
   
    {
      name: 'Orders',
      tag: 'orders',
    },
    {
      name: 'BuyerOrders',
      tag: 'buyer-order',
    },
    {
      name: 'category',
      tag: 'category',
    },
    {
      name: 'foods',
      tag: 'foods',
    },
    {
      name: 'User',
      tag: 'users'
    },
  ];
  
  
  export const getTagsByModuleName = (moduleName) => {
    return tags
      .filter(tag => tag.name.toLowerCase() === moduleName.toLowerCase())
      .map(tag => tag.tag);
  };
  