import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BaseURL } from "../../../Helper/config";
import Swal from "sweetalert2";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { ToastContainer, toast } from "react-toastify";
import {
  ImageUploader,
  onUploadProgress,
} from "../../../ImageUploader/ImageUploader";

import { useForm } from "react-hook-form";
import labels from "../../../translationData/food.json";

const UpdateFood = () => {
  const sellerID = getUserDetails().sellerIDInfo[0]?._id;
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const [foodFormData, setFoodFormData] = useState({});

  const [selectedImage, setSelectedImage] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [catID, setCatID] = useState([]);

  const [cat, setcat] = useState();
  const [selectedSubCategoryID, setSelectedSubCategoryID] = useState();

  const [subcat, setSubCat] = useState([]);

  const [Tag, setTag] = useState([]);

  const [additioanlTag, setAdditionalTag] = useState([]);

  const [metaImage, setMetaImage] = useState([]);

  const [selectedDays, setSelectedDays] = useState({
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false,
  });
  const handleDayClick = (day) => {
    setSelectedDays({
      ...selectedDays,
      [day]: !selectedDays[day],
    });
  };
  const [applyEveryWeek, setApplyEveryWeek] = useState(false);
  const handleApplyEveryWeekToggle = () => {
    setApplyEveryWeek(!applyEveryWeek);
  };

  //start handle instant catering on change method
  const [insCatData, setInsCat] = useState("");
  const handleInCa = (e) => {
    const value = e.target.value;
    console.log(value);
    setInsCat(value);
  };

  const [selectedIsDiscountAnyOption, setSelectedIsDiscountAnyOption] =
    useState("");

  const handleIsDiscountAnyOptionChange = (event) => {
    const selectedDiscountOption = event.target.value;
    setSelectedIsDiscountAnyOption(selectedDiscountOption);

    setDiscountPrice("");
    setDiscountPercentage("");
    setFoodPriceAfterDiscount("");
  };
  const [editorData, setEditorData] = useState();
  const [foodPrice, setFoodPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [foodPriceAfterDiscount, setFoodPriceAfterDiscount] = useState("");

  const handleDiscountPrice = (event) => {
    const price = parseFloat(event.target.value);
    console.log(price);
    setDiscountPrice(price);
    setDiscountPercentage("");
    setFoodPriceAfterDiscount("");
    calculatePriceAfterDiscount(price);
  };

  const handleDiscountPercentage = (event) => {
    const percentage = parseFloat(event.target.value);
    setDiscountPercentage(percentage);
    setDiscountPrice("");
    setFoodPriceAfterDiscount("");
    calculatePriceAfterDiscount(percentage);
  };
  const calculatePriceAfterDiscount = (updatedDiscountPrice) => {
    if (
      !isNaN(foodPrice) &&
      !isNaN(discountPercentage) &&
      discountPercentage > 0
    ) {
      const discountAmount = (foodPrice * updatedDiscountPrice) / 100;
      const discountedPrice = foodPrice - discountAmount;
      setFoodPriceAfterDiscount(discountedPrice);
    } else if (
      !isNaN(foodPrice) &&
      !isNaN(updatedDiscountPrice) &&
      updatedDiscountPrice > 0
    ) {
      const discountedPrice = foodPrice - updatedDiscountPrice;
      setFoodPriceAfterDiscount(discountedPrice);
    } else {
      setFoodPriceAfterDiscount("");
    }
  };

  //--------------------------------------
  const handleMetaImage = (event) => {
    console.log("meta image");
    const fileList = Array.from(event.target.files);
    setMetaImage(fileList);
    console.log(metaImage, fileList, "meta image");
  };

  //----------------------------------------
  const handleImageDeleteFood = (index) => {
    const updatedImages = [...selectedImage];
    updatedImages.splice(index, 1);

    setSelectedImage(updatedImages);
  };


  useEffect(() => {
    // Retrieve token from localStorage
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};  // Set token header
  
    const getFood = async () => {
      fetch(`${BaseURL}/get-single-food/${params.id}`, { headers })
        .then((res) => res.json())
        .then((data) => {
          setFoodFormData(data.data[0].Rows[0]);
          const food_data = data.data[0].Rows[0];
          console.log("fooddata", food_data);
  
          if (food_data?.foodImage && food_data?.foodImage.length > 0) {
            var imagesCollection = [];
  
            food_data?.foodImage.forEach((images, index) => {
              if (images && images.orginalImageUrl) {
                var toBePushed = { ...images, isExisting: true };
                imagesCollection.push(toBePushed);
              }
            });
  
            setSelectedImage(imagesCollection);
          }
  
          if (food_data.foodType) {
            setInsCat(food_data.foodType);
          }
  
          if (food_data.foodAvailableDays && food_data.foodAvailableDays.length > 0) {
            setSelectedDays(food_data.foodAvailableDays[0]);
          }
  
          setApplyEveryWeek(food_data.foodAvailableSameDaysEveryWeek);
  
          if (food_data.foodPrice !== 0) {
            setFoodPrice(food_data.foodPrice);
          }
  
          if (food_data.foodIsDiscountAny) {
            setSelectedIsDiscountAnyOption(food_data.foodIsDiscountAny);
            setDiscountPrice(food_data.foodDiscountPrice);
            setDiscountPercentage(food_data.foodDiscountPercentage);
            setFoodPriceAfterDiscount(food_data.foodPriceAfterDiscount);
          }
  
          if (food_data.foodIsDiscountAny === "DiscountPrice") {
            // Additional discount logic
          }
          if (food_data.categoryID) {
            setcat(food_data.categoryID);
          }
          if (food_data.subCategoryID) {
            setSelectedSubCategoryID(food_data.subCategoryID);
          }
  
          if (food_data.foodAdditionalTags && food_data.foodAdditionalTags.length > 0) {
            setTag(food_data.foodAdditionalTags);
          } else {
            setTag([]);
          }
        });
    };
    getFood();
  
    axios
      .get(`${BaseURL}/get-additionaltags`, { headers })
      .then((res) => {
        setAdditionalTag(res.data.data);
        console.log("additionaldata", res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  
    const getCat = () => {
      axios
        .get(`${BaseURL}/get-category`, { headers })
        .then((res) => setCatID(res.data.data))
        .catch((err) => {
          console.log(err);
        });
    };
    getCat();
  }, []);  // Empty dependency array to run once on mount

  // useEffect(() => {
  //   setValue("name", foodFormData ? foodFormData.foodName : "");
  //   setValue("quantity", foodFormData ? foodFormData.foodQty : "");
  //   setValue("category", foodFormData ? foodFormData.categoryID : "");
  //   setValue("subcategory", foodFormData ? foodFormData.subCategoryID : "");
  //   setValue("foodVideoURL", foodFormData ? foodFormData.foodVideoURL : "");
  //   setValue("portionSize", foodFormData ? foodFormData.foodPortionSize : "");
  // }, []); // Empty dependency array means this effect runs only once

  const token = localStorage.getItem("Token");
  const headers = token ? { token: `${token}` } : {}; 

  useEffect(() => {
    // Fetch subcategory data when category changes
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {}; 
    const fetchSubcategories = () => {
      let url;
      if (cat) {
        url = `${BaseURL}/get-subcategories-by-category-by-seller/${sellerID}/${cat}`;
      } else {
        url = `${BaseURL}/get-subcategories-by-category-by-seller/${sellerID}`;
      }
  
      axios
        .get(url, { headers })
        .then((res) => setSubCat(res?.data?.data))
        .catch((err) => console.error("Error fetching subcategories:", err));
    };
    fetchSubcategories();
  }, [cat]);  // Dependency array with `cat` to refetch when `cat` changes
  

  const handleCheck = (e) => {
    if (e.target.checked) {
      setTag((oldArray) => [...oldArray, e.target.value]);
    } else {
      removeTag(e);
      console.log(Tag);
    }
  };

  const removeTag = (e) => {
    setTag([...Tag.filter((Tag) => Tag !== e.target.value)]);
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    var foodImageCollection = [...selectedImage];
    fileList.map((item, index) => {
      foodImageCollection.push({ images: [item], isExisting: false });
    });

    setSelectedImage(foodImageCollection);
  };

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const handleCreateFood = async (value) => {
    console.log("Value data:", value);
    const foodType = insCatData;
    const FoodName = value.name;
    // const Slug = value.slug;
    // const description = value.description;
    const description = editorData;

    // কয়জনের জন্য বিক্রী করবেন?
    const Quantity = value.quantity;
    const Price = value.price;

    const foodIsDiscountAny = selectedIsDiscountAnyOption;
    const discountedPrice = discountPrice;
    const discountedPercentage = discountPercentage;
    const foodPriceAfterDiscounted = foodPriceAfterDiscount;
    // প্রতিজনে খাবারের পরিমান কতটুকু?
    const Portion = value.portionSize;

    // const Category = value.category;
    // const Subcategory = value.subcategory;
    const StartDate = value.startdate;
    const EndDate = value.enddate;

    // const additionalInfo = value.foodAdditionalInfo;
    // const FoodMinQty = value.minimumquantity;

    // const food_creation_date = value.food_creation_date;
    // const food_creation_time = value.food_creation_time;

    const metaTitle = value.metaTitle;
    const metaDescription = value.metaDescription;
    const metaKeyword = value.metaKeywords;
    // const AvailableSameDaysEachWeek = value.foodAvailableSameDaysEachWeek;

    // const metaimage = value.metaImage;
    // console.log(metaimage, "from meta image");

    const FoodData = {
      sellerID: getUserDetails().sellerIDInfo[0]?._id,

      categoryID: cat,
      subCategoryID: selectedSubCategoryID,
      // cuisineCollection: { type: Array },
      // sellerImage: { type: String },
      sellerName: getUserDetails().sellerIDInfo[0]?.userFullName,
      foodName: FoodName,
      foodDescription: description,
      foodType: foodType, //Instant /preOrder /wish /catering
      // food_creation_date: food_creation_date,
      // food_creation_time: food_creation_time,
      // foodSlug: { type: String },
      foodImage: [],
      foodVideoURL: value.foodVideoURL,
      // foodThumb: { type: String },
      // foodVideo: { type: String },
      foodQty: Quantity,
      foodPrice: Price,
      foodIsDiscountAny: selectedIsDiscountAnyOption,
      foodDiscountPrice: discountPrice,
      foodDiscountPercentage: discountPercentage,
      foodPriceAfterDiscount: foodPriceAfterDiscount,

      foodPortionSize: Portion,
      // foodMinimumOrderQty: FoodMinQty,
      // foodAdditionalInfo: { type: String },
      // foodAdditionalInfo: additionalInfo,
      foodAdditionalTags: Tag,
      seo: {
        metaTitle: metaTitle,
        metaDescription: metaDescription,
        metaKeywords: metaKeyword,
        metaImage: [],
      },
      // foodExpiryTime: { type: String }, /n

      // foodOrderBeforeTime: { type: String },
      // foodRating: { type: Number },
      // status: { type: Boolean },
      // foodLabel: { type: Array }, //instant preorder capitilized n
      // customerRequestedFoodQty: { type: String },
      // customerDesireDeliveryDate: { type: String },
      // customerDesireDeliveryTime: { type: String },
      // createdBy: { type: String },
      // updatedBy: { type: String },
      // BSR: { type: Number },
    };

    if (foodType === "INSTANT") {
      const activatedForHours = value.foodActiveForHours;
      FoodData["foodActiveForHours"] = activatedForHours;
    } else if (foodType === "PREORDER" || foodType === "CATERING") {
      FoodData["foodDiscountStartDate"] = StartDate;
      FoodData["foodDiscountEndDate"] = EndDate;
      FoodData["foodOrderBeforeTime"] = value.foodOrderBeforeTime;
    }
    if (foodType === "PREORDER") {
      FoodData["foodAvailableDays"] = selectedDays;
      FoodData["foodAvailableSameDaysEveryWeek"] = applyEveryWeek;
    }

    var newSelectedImages = selectedImage
      .filter((m) => !m.isExisting)
      .map((item) => item.images[0]);

    if (newSelectedImages) {
      setIsFileUploading(true);
      const imageUploadedResult = await ImageUploader(
        Seller,
        "Food",
        newSelectedImages
      );

      if (imageUploadedResult && imageUploadedResult.status === "Success") {
        FoodData.foodImage = [
          ...selectedImage.filter(
            (m) => m.orginalImageUrl !== "" && m.isExisting
          ),
          ...imageUploadedResult.images,
        ];
        setIsFileUploading(false);
      }
    }
    if (!isFileUploading) {
      console.log("final food data", FoodData);
      await axios
        .post(`${BaseURL}/update-food/${params.id}`, FoodData, { headers })
        .then((apiResponse) => {
          console.log(apiResponse.data);
  
          Swal.fire({
            icon: "success",
            title: "Food edited successfully!!!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            // navigate("/my-food");
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("❌ Error Notify !", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <div className="container d-flex create-food-container">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Edit Food</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit(handleCreateFood)}>
                <div className=" form-group mb-3">
                  <label>{labels.foodType.bn} </label>
                  <select
                    // name='foodType'
                    {...register("foodType", { required: true })}
                    className="form-control input-default "
                    value={insCatData || "INSTANT"}
                    defaultValue={insCatData || "INSTANT"}
                    onChange={handleInCa}
                  >
                    <option value="INSTANT">এখনই (Instant)</option>
                    <option value="PREORDER">
                      মেনুভিত্তিক অগ্রীম অর্ডার (Pre-Order){" "}
                    </option>
                    <option value="CATERING">
                      ক্যাটারিং বা বড় অর্ডার (Catering){" "}
                    </option>

                    {/* <option value="advancedmenu">ADVANCED MENU</option>
                      <option value="menu">MENU</option> */}

                    {/* <option value='catering'> CATERING</option> */}
                  </select>
                  {errors.foodType && (
                    <span className="text-danger">This Field is required</span>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label>{labels.foodName.bn}</label>
                  <input
                    type="text"
                    className="form-control input-default"
                    placeholder={labels.foodName.bn}
                    label="Name:"
                    name="name"
                    {...register("name", { required: false })}
                    defaultValue={foodFormData ? foodFormData.foodName : ""}
                    onChange={(e) =>
                      setFoodFormData({
                        ...foodFormData,
                        foodName: e.target.value,
                      })
                    }
                  />

                  {errors.name && (
                    <span className="text-danger">Food Name is required</span>
                  )}
                </div>

                <div className="row justify-content-center mt-3 mb-5 ">
                  <div>
                    <div>
                      <label htmlFor="formFile" className="form-label">
                        {labels.foodPhoto.bn}
                      </label>
                    </div>

                    {!selectedImage &&
                      selectedImage?.map((image, imageIndex) => (
                        <img
                          width={"150px"}
                          height={"150px"}
                          key={imageIndex}
                          className="me-3 rounded mb-2"
                          src={image.orginalImageUrl}
                          alt={`Image ${imageIndex}`}
                        />
                      ))}

                    {selectedImage?.map((item, index) => {
                      return (
                        <>
                          <img
                            className="img-fluid"
                            alt="Food Image"
                            width={"150px"}
                            height={"150px"}
                            src={
                              item.isExisting
                                ? item.orginalImageUrl
                                : URL.createObjectURL(item.images[0])
                            }
                          />
                          <span
                            style={{
                              position: "relative",
                              top: "-30px",
                              left: "-26px",
                            }}
                          >
                            <div
                              className="btn btn-danger shadow btn-xs sharp"
                              onClick={() => handleImageDeleteFood(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </div>
                          </span>
                        </>
                      );
                    })}
                    <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <input
                        name="readyFoodImage"
                        type="file"
                        id="input-file-upload"
                        multiple
                        onChange={handleFileChange}
                      />
                      <label id="label-file-upload" htmlFor="input-file-upload">
                        <div>
                          <p>Drag and drop your file here or</p>
                          <span className="upload-button">Upload a file</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">খাবারের ভিডিও লিঙ্ক</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Video URL"
                    // name="foodVideoURL"
                    {...register("foodVideoURL", { required: false })}
                    defaultValue={
                      (foodFormData && foodFormData.foodVideoURL) || ""
                    }
                    // value={foodFormData ? foodFormData.foodVideoURL : ""}
                    onChange={(e) =>
                      setFoodFormData({
                        ...foodFormData,
                        foodVideoURL: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group mb-3">
                  <label>{labels.foodDescription.bn}</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"],
                    }}
                    data={(foodFormData && foodFormData.foodDescription) || ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorData(data);
                    }}
                    style={{ height: 20 }}
                  />
                </div>

                {insCatData !== "INSTANT" &&
                insCatData !== "CATERING" &&
                insCatData === "PREORDER" ? (
                  <div className="row">
                    <div className=" form-group mb-3 col-lg-6">
                      <label>কোন কোন দিন এই খাবারের জন্য পাজাব যাবে </label>

                      <div className="days-container">
                        {selectedDays &&
                          Object.keys(selectedDays).map((day) => (
                            <div
                              key={day}
                              className={`day ${
                                selectedDays[day] ? "day-selected" : ""
                              }`}
                              onClick={() => handleDayClick(day)}
                            >
                              {day}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className=" form-group mb-3 col-lg-3">
                      <label>
                        <input
                          type="checkbox"
                          checked={applyEveryWeek}
                          onChange={handleApplyEveryWeekToggle}
                        />
                        Apply same days for every week
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="form-group mb-3 col-lg-4">
                    <label>{labels.foodQuantity.bn}</label>
                    <input
                      type="number"
                      className="form-control input-default "
                      placeholder={labels.foodQuantity.bn}
                      label="quantity:"
                      // name='quantity'
                      {...register("quantity", { required: false })}
                      defaultValue={
                        (foodFormData && foodFormData.foodQty) || ""
                      }
                      // value={foodFormData ? foodFormData.foodQty : ""}
                      onChange={(e) =>
                        setFoodFormData({
                          ...foodFormData,
                          foodQty: e.target.value,
                        })
                      }
                    />
                    {errors.quantity && (
                      <span className="text-danger">
                        This Field is required
                      </span>
                    )}
                  </div>

                  <div className="form-group mb-3 col-lg-4">
                    <label>{labels.portion.bn}</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={labels.portion.bn}
                      name="portionSize"
                      {...register("portionSize", { required: false })}
                      defaultValue={
                        (foodFormData && foodFormData.foodPortionSize) || ""
                      }
                      // value={foodFormData ? foodFormData.foodPortionSize : ""}
                      onChange={(e) =>
                        setFoodFormData({
                          ...foodFormData,
                          foodPortionSize: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form-group mb-3 col-lg-4">
                    <label>{labels.foodPrice.bn} *</label>
                    <input
                      type="number"
                      className="form-control input-default "
                      placeholder={labels.foodPrice.bn}
                      label="price:"
                      // name='price'
                      {...register("price", { required: false })}
                      defaultValue={
                        (foodFormData && foodFormData.foodPrice) || ""
                      }
                      // value={foodFormData ? foodFormData.foodPrice : ""}
                      onChange={(e) => {
                        const price = e.target.value;
                        setFoodFormData({ ...foodFormData, foodPrice: price });
                        setFoodPrice(price);
                      }}

                      // onChange={(e) =>
                      //   setFoodPrice(e.target.value);
                      //   setFoodFormData({ ...foodFormData, foodPrice: e.target.value })
                      // }
                    />
                    {errors.price && (
                      <span className="text-danger">
                        This Field is required
                      </span>
                    )}
                  </div>

                  <div className="form-group mb-3 col-lg-4">
                    <label>{labels.foodIsDiscountAny.bn} </label>

                    <div>
                      <label>
                        <input
                          type="radio"
                          value="NoDiscount"
                          checked={selectedIsDiscountAnyOption === "NoDiscount"}
                          onChange={handleIsDiscountAnyOptionChange}
                        />
                        {labels.foodIsDiscountOptionNoDiscount.bn}
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="DiscountPrice"
                          checked={
                            selectedIsDiscountAnyOption === "DiscountPrice"
                          }
                          onChange={handleIsDiscountAnyOptionChange}
                        />
                        {labels.foodIsDiscountOptionPriceDiscountTk.bn}
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="DiscountPercentage"
                          checked={
                            selectedIsDiscountAnyOption === "DiscountPercentage"
                          }
                          onChange={handleIsDiscountAnyOptionChange}
                        />
                        {labels.foodIsDiscountOptionPriceDiscountPercentage.bn}
                      </label>
                    </div>
                  </div>

                  {selectedIsDiscountAnyOption === "DiscountPrice" && (
                    <>
                      <div className="form-group mb-3 col-lg-4">
                        <label>{labels.foodDiscountPrice.bn}</label>
                        <input
                          type="number"
                          className="form-control input-default"
                          placeholder={labels.foodDiscountPrice.bn}
                          label="discountPrice:"
                          name="discountPrice"
                          defaultValue={discountPrice}
                          onChange={handleDiscountPrice}
                        />
                      </div>
                      <div className="form-group mb-3 col-lg-4">
                        <label>{labels.foodPriceAfterDiscount.bn} </label>
                        <input
                          type="text"
                          className="form-control input-default"
                          placeholder={labels.foodPriceAfterDiscount.bn}
                          label=":"
                          value={foodPriceAfterDiscount}
                        />
                      </div>
                    </>
                  )}

                  {selectedIsDiscountAnyOption === "DiscountPercentage" && (
                    <>
                      <div className="form-group mb-3 col-lg-4">
                        <label>{labels.foodDiscountPercentage.bn}</label>
                        <input
                          type="number"
                          className="form-control input-default"
                          placeholder={labels.foodDiscountPercentage.bn}
                          label="discountPercentage:"
                          name="discountPercentage"
                          defaultValue={discountPercentage}
                          onChange={handleDiscountPercentage}
                        />
                      </div>
                      <div className="form-group mb-3 col-lg-4">
                        <label>{labels.foodPriceAfterDiscount.bn} </label>
                        <input
                          type="text"
                          className="form-control input-default"
                          placeholder={labels.foodPriceAfterDiscount.bn}
                          label=":"
                          value={foodPriceAfterDiscount}
                        />
                      </div>
                    </>
                  )}
                </div>

                {insCatData !== "INSTANT" &&
                (selectedIsDiscountAnyOption === "DiscountPrice" ||
                  selectedIsDiscountAnyOption === "DiscountPercentage") ? (
                  <div className="row">
                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.discountStart.bn}</label>
                      <input
                        type="date"
                        className="form-control input-default"
                        placeholder={labels.discountStart.bn}
                        label="startdate:"
                        name="startdate"
                        {...register("startdate", { required: false })}
                        defaultValue={
                          (foodFormData &&
                            foodFormData.foodDiscountStartDate) ||
                          ""
                        }
                      />
                    </div>

                    <div className="form-group mb-3 col-lg-4">
                      <label>{labels.discountEnd.bn}</label>
                      <input
                        type="date"
                        className="form-control input-default"
                        placeholder={labels.discountEnd.bn}
                        label="enddate:"
                        name="enddate"
                        {...register("enddate", { required: false })}
                        defaultValue={
                          (foodFormData && foodFormData.foodDiscountEndDate) ||
                          ""
                        }
                      />
                    </div>
                  </div>
                ) : null}

                <div className="row">
                  <div className="form-group mb-3  col-lg-4">
                    <label>{labels.category.bn}</label>
                    <select
                      defaultValue={foodFormData ? foodFormData.categoryID : ""}
                      // name='category'
                      {...register("category", { required: false })}
                      // onClick={getCat}
                      onChange={(event) => setcat(event.target.value)}
                      // value={currentFruit}
                      className="form-control"
                    >
                      <option value="option" disabled>
                        Select Category
                      </option>

                      {/* {catID?.map((item, index) => ( */}
                      {catID.map((item, index) => {
                        if (
                          foodFormData &&
                          foodFormData.categoryID === item._id
                        ) {
                          return (
                            <option key={index} value={item._id} selected>
                              {item.categoryName}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={item._id}>
                              {item.categoryName}
                            </option>
                          );
                        }
                      })}
                    </select>
                    {errors.category && (
                      <span className="text-danger">
                        This Field is required
                      </span>
                    )}
                  </div>
                  <div className="form-group mb-3  col-lg-4">
                    <label>{labels.subCat.bn}</label>
                    <select
                      defaultValue={
                        foodFormData ? foodFormData.subCategoryID : ""
                      }
                      // name='subcategory'
                      className="form-control"
                      {...register("subcategory", { required: false })}
                    >
                      <option value="option" disabled>
                        Select Sub Category
                      </option>
                      {subcat.map((item, index) => {
                        if (
                          foodFormData &&
                          foodFormData.subCategoryID === item._id
                        ) {
                          return (
                            <option key={index} value={item._id} selected>
                              {item.categoryName}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={item._id}>
                              {item.categoryName}
                            </option>
                          );
                        }
                      })}
                    </select>
                    {errors.subcategory && (
                      <span className="text-danger">
                        This Field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-3 row">
                  <label> Additional Tags (optional)</label>
                  {additioanlTag.map((item, index) => (
                    <div className="col-lg-3 col-md-3 ">
                      <span key={index}>
                        {item.status === true ? (
                          <span className="form-check custom-checkbox mb-3 ms-3 checkbox-info d-flex">
                            <input
                              onChange={(e) => {
                                handleCheck(e);
                              }}
                              type="checkbox"
                              name={item.tagName}
                              // {...register(`${item.tagName}`, {
                              //   // required: true,
                              // })}
                              value={item.tagName}
                              className="form-check-input"
                              id={`customCheckBox${index}`}
                              checked={Tag && Tag.includes(item.tagName)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={`customCheckBox${index}`}
                            >
                              {item.tagName}
                            </label>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ))}
                </div>

                {insCatData === "INSTANT" ? (
                  <div className="mt-3 row">
                    <div className="form-group mb-3  col-lg-3">
                      <label> এই আইটেমটি বিক্রির জন্য কত ঘন্টা সক্রিয়?</label>
                    </div>
                    <div className="form-group  col-lg-4">
                      <select
                        // name='foodActiveForHours'
                        {...register("foodActiveForHours", { required: true })}
                        className="form-control input-default "
                        defaultValue={
                          foodFormData ? foodFormData.foodActiveForHours : "12"
                        }
                        // value={foodFormData ? foodFormData.foodActiveForHours : "12"}
                        onChange={(e) =>
                          setFoodFormData({
                            ...foodFormData,
                            foodActiveForHours: e.target.value,
                          })
                        }
                      >
                        <option value="12">১২ ঘন্টা জন্য</option>
                        <option value="24">২৪ ঘন্টা জন্য</option>
                        <option value="36">৩৬ ঘন্টা জন্য</option>
                      </select>
                      {errors.foodActiveForHours && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {insCatData !== "INSTANT" ? (
                  <div className="mt-3 row">
                    <div className="form-group mb-3  col-lg-3">
                      <label> Buyer has to offer at least before</label>
                    </div>
                    <div className="form-group  col-lg-4">
                      <select
                        // name='foodOrderBeforeTime'
                        {...register("foodOrderBeforeTime", { required: true })}
                        className="form-control input-default "
                        defaultValue={foodFormData.foodOrderBeforeTime}
                        // value={foodFormData ? foodFormData.foodOrderBeforeTime : "1 day"}
                        onChange={(e) =>
                          setFoodFormData({
                            ...foodFormData,
                            foodOrderBeforeTime: e.target.value,
                          })
                        }

                        // onChange={handleInCa}
                      >
                        <option value="1 day">১ দিন</option>
                        <option value="2 days">২ দিন</option>
                        <option value="18 hours">১৮ ঘন্টা </option>
                        <option value="12 hours">১২ ঘন্টা </option>
                      </select>
                      {errors.foodOrderBeforeTime && (
                        <span className="text-danger">
                          This Field is required
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row d-none">
                  <div className="form-group mb-3 col-lg-3">
                    <label>{labels.metatitle.bn}</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={labels.metatitle.bn}
                      label="metaTitle:"
                      // name='metaTitle'
                      {...register("metaTitle", { required: false })}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-lg-3">
                    <label>{labels.metaDescription.bn}</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={labels.metaDescription.bn}
                      label="metaDescription"
                      // name='metaDescription'
                      {...register("metaDescription", { required: false })}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-lg-3">
                    <label>{labels.metaKeywords.bn}</label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={labels.metaKeywords.bn}
                      label="metaKeywords"
                      // name='metaKeywords'
                      {...register("metaKeywords", { required: false })}
                      // value={data?.price}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label>{labels.metaImg.bn}</label>
                    <input
                      type="file"
                      className="form-control input-default "
                      placeholder={labels.metaImg.bn}
                      label="metaImage"
                      name="metaImage"
                      // value={data?.price}
                      onChange={handleMetaImage}
                    />
                  </div>
                </div>

                <div className="mt-3 row">
                  <div className="form-group mb-3  col-lg-3">
                    <Button style={{ marginLeft: 25 }} type="submit">
                      {" "}
                      Update Food
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateFood;
