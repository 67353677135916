import React from 'react'
import { getUserDetails } from '../../../Helper/SessionHelper';
import { useGetSellerCouponQuery } from '../../../Redux/API/ApiSlice';
import CouponCard from '../../Buyer/Coupon/Coupon';

const CouponListSeller = () => {
  const sellerID = getUserDetails()?.sellerIDInfo[0]?._id;

  const {data,isSuccess} = useGetSellerCouponQuery(sellerID)
  return (
  <div className="container d-flex justify-content-center mt-5">
      {
        isSuccess && data.data.map(item => <CouponCard data={item} />)
      }

    </div>
  )
}

export default CouponListSeller