 /* eslint-disable react/display-name */
 import  { forwardRef } from "react";
 import "../../../css/invoice.css";
 import moment from "moment";
import image1 from "../../../images/own2.jpeg";


const BillReceipt = forwardRef(({ data }, ref) => {
  return (
    <div ref={ref} className="BillReceipt">
      <div className="centeredContent">
        <img src={image1} alt="" width="120px" />
        <h5 className="orderNumber">ORDER NO: {data?.orderNumber}</h5>
        <h5 className="orderNumber">
          Order Date:{" "}
          {moment(data?.orderDate).format("MMMM Do YYYY, h:mm:ss a")}
        </h5>
      </div>


      <div className="paidWith">
        <h4 className="Title">Order From:</h4>
        <p className="kitchenName">{data?.seller_INFO[0]?.kitchenName}</p>
      </div>


      <div className="paidWith">
        <h4 className="Title">Delivered To:</h4>
        <p className="kitchenName">{data?.userInfo[0]?.userFullName}</p>
        <p className="kitchenName">Number: {data?.userInfo[0]?.userMobileNo}</p>
        <p className="kitchenName">{data?.deliveryAddress[0]?.addressText}</p>
      </div>


      <div className="paidWith">
        <p className="kitchenName">
          <strong className="Title">Note:</strong> “Thank you for dining with
          us!”
        </p>
      </div>


      <h4 className="paidWith Title">Order Summary</h4>
      <div>
        {data?.orderItems?.map((item, index) => {
          const itemPrice = item.foodPriceAfterDiscount || item.foodPrice;
          const discountInfo = () => {
            if (item.foodIsDiscountAny === "DiscountPercentage") {
              return `${item.foodDiscountPercentage}%`;
            } else if (item.foodIsDiscountAny === "DiscountPrice") {
              return `Tk ${item.foodDiscountPrice}`;
            }
            return "No discount";
          };


          return (
            <div key={index}>
              <div className="OrderSummary">
                <h5 className="kitchenName">
                  {item.foodQty} x {item.foodName}
                </h5>
                <h5 className="kitchenName">Tk {itemPrice * item.foodQty}</h5>
              </div>


              <div className="OrderSummary">
                <p className="kitchenName">Discount:</p>
                <p className="kitchenName">{discountInfo()}</p>
              </div>
            </div>
          );
        })}
      </div>


      <div className="OrderSummary">
        <p className="kitchenName">Service Charge</p>
        <p className="kitchenName">Tk {data?.serviceCharge || 0}</p>
      </div>


      <div className="OrderSummary">
        <p className="kitchenName">Delivery Fee</p>
        <p className="kitchenName">Tk {data?.deliveryCharge}</p>
      </div>


      <div className="OrderSummary">
        <h5 className="Title">Total</h5>
        <h5 className="Title">Tk {data?.orderTotalAmount || "N/A"}</h5>
      </div>


      {data?.partialPaymentAmount?.length > 0 && (
        <div className="OrderSummary">
          <h5 className="Title">Advance Paid</h5>
          <h5 className="Title">Tk {data?.partialPaymentAmount}</h5>
        </div>
      )}


      {data?.paySystemName !== "payNow" && (
        <div className="OrderSummary">
          <h5 className="Title">Due</h5>
          <h5 className="Title">
            Tk {data?.orderTotalAmount - (data?.partialPaymentAmount?.[0] || 0)}
          </h5>
        </div>
      )}


      {(data?.paySystemName === "payNow" ||
        data?.paySystemName === "advancePayment") && (
        <>
          <h4 className="paidWith Title">Paid with</h4>
          <div className="OrderSummary">
            <p className="kitchenName">{data?.methodName || "N/A"}</p>
            <p className="kitchenName">Tk {data?.payableAmount || "N/A"}</p>
          </div>
        </>
      )}
    </div>
  );
});


export default BillReceipt;
